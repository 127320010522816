import { card, container, main } from "assets/jss/material-kit-pro-react";

const CheckoutStyle = {
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  main: {
    ...main,
    background: "transparent",
  },
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    ...card,
    margin: "10px 0",
    padding: 10,
  },
  submitOrderButtonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  submitOrderError: {
    marginTop: 20,
    padding: "10px 20px",
    color: "red",
    fontWeight: "400",
    border: "2px solid red",
  },
};

export default CheckoutStyle;
