import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GoogleMap, LoadScriptNext, Marker } from "@react-google-maps/api";
import MapConfirmButton from "./MapConfirmButton";
import MapSearch from "./MapSearch";
import AlertDialog from "../AlertDialog/AlertDialog";
import { isEmpty } from "../../../constants/utils";

export default function CartMap(props) {
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const { outlet_latitude, outlet_longitude } = menuInfo.menuData;
  const {
    deliveryLocation,
    deliveryPlace,
    deliveryLocationConfirmed,
  } = cartInfo;

  const containerStyle = {
    width: "100%",
    height: "100vh",
  };

  const [defaultPosition, setDefaultPosition] = useState(deliveryLocation);
  const [selectedPlace, setSelectedPlace] = useState(deliveryPlace);
  const [mapInstance, setMapInstance] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const onDragEnd = (marker) => {
    const { latLng } = marker;
    setDefaultPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
  };

  const [libraries] = useState(["places"]);

  const onLoad = useCallback(
    function onLoad(mapInstance) {
      setMapInstance(mapInstance);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const extractGeocoderResult = (components, type) => {
    for (var i = 0; i < components.length; i++)
      for (var j = 0; j < components[i].types.length; j++)
        if (components[i].types[j] === type) return components[i];
    return null;
  };

  useEffect(() => {
    if (isEmpty(deliveryPlace) && isEmpty(deliveryLocation)) {
      setDefaultPosition({
        lat: outlet_latitude,
        lng: outlet_longitude,
      });
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setDefaultPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          setDefaultPosition({
            lat: outlet_latitude,
            lng: outlet_longitude,
          });
        },
        {
          enableHighAccuracy: true,
          maximumAge: 1000,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPlace, deliveryLocation]);

  useEffect(() => {
    if (mapInstance && !isEmpty(defaultPosition)) {
      const geocode = new window.google.maps.Geocoder();
      geocode
        .geocode({
          latLng: new window.google.maps.LatLng(
            defaultPosition.lat,
            defaultPosition.lng
          ),
        })
        .then((res) => {
          // console.log(res.results);
          const sublocality = extractGeocoderResult(res.results, "sublocality");
          const neighborhood = extractGeocoderResult(
            res.results,
            "neighborhood"
          );
          if (sublocality) {
            setSelectedPlace(sublocality);
          } else if (neighborhood) {
            setSelectedPlace(neighborhood);
          } else {
            setSelectedPlace(res.results[0]);
          }
        });
    }
  }, [mapInstance, defaultPosition]);

  return (
    <LoadScriptNext
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
      language={navigator.language}
    >
      {!isEmpty(defaultPosition) && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={defaultPosition}
          zoom={16}
          clickableIcons={false}
          onLoad={onLoad}
        >
          <Marker
            position={defaultPosition}
            draggable={!deliveryLocationConfirmed}
            onDragEnd={onDragEnd}
          />
        </GoogleMap>
      )}

      <MapSearch
        defaultPosition={defaultPosition}
        setDefaultPosition={setDefaultPosition}
      />
      <MapConfirmButton
        defaultPosition={defaultPosition}
        selectedPlace={selectedPlace}
        setAlertOpen={setAlertOpen}
      />
      <AlertDialog
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertTitle={"We're not there yet"}
        alertContent={
          "The delivery location is still outside of our delivery area. However, you can try a different location or select the pickup option to get your order at the outlet"
        }
        buttonText={"Change Location"}
      />
    </LoadScriptNext>
  );
}
