import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { savePaymentType } from "../../../store/actions/cart";
import { isEmpty } from "../../../constants/utils";

const useStyles = makeStyles(CartStyle);

export default function CartPaymentType(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const {
    ui_strings,
    enable_payment_type,
    enable_cash,
    enable_card_machine,
    payment_enabled,
    enable_address_google_maps,
    theme_data,
  } = menuInfo.menuData;
  const {
    orderType,
    deliveryLocation,
    deliveryLocationConfirmed,
    deliveryFormattedAddress,
    paymentType: cPaymentType,
    enableWishlist,
    minOrderAmountError,
  } = cartInfo;

  const [paymentType, setPaymentType] = useState(
    cPaymentType ? cPaymentType : "cash"
  );

  const handlePaymentType = (event, value) => {
    if (value) {
      setPaymentType(value);
    }
  };

  const toggleButtonStyle = {
    textTransform: theme_data?.cart_screen_toggle_btn_text_transform
      ? theme_data.cart_screen_toggle_btn_text_transform
      : "uppercase",
  };

  useEffect(() => {
    if (paymentType) {
      dispatch(savePaymentType(paymentType));
    } else {
      dispatch(savePaymentType(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType]);

  return (
    !enableWishlist &&
    !minOrderAmountError &&
    enable_payment_type &&
    orderType &&
    ((enable_address_google_maps &&
      !isEmpty(deliveryLocation) &&
      deliveryLocationConfirmed &&
      deliveryFormattedAddress &&
      orderType === "delivery") ||
      (!enable_address_google_maps && orderType === "delivery") ||
      orderType !== "delivery") && (
      <div className={classes.paymentTypeContainer}>
        <div className={classes.paymentTypeTitle}>
          {ui_strings?.ui_str_payment_type
            ? ui_strings.ui_str_payment_type
            : "Payment Type"}
        </div>
        <ToggleButtonGroup
          value={paymentType}
          exclusive
          onChange={handlePaymentType}
          size={"small"}
          aria-label={
            ui_strings?.ui_str_payment_type
              ? ui_strings.ui_str_payment_type
              : "Payment Type"
          }
          className={classes.paymentTypeButtonGroup}
        >
          {enable_cash && (
            <ToggleButton
              value={"cash"}
              aria-label={
                ui_strings?.ui_str_cash ? ui_strings.ui_str_cash : "Cash"
              }
              selected={paymentType === "cash"}
              classes={{
                root: classes.toggleButton,
                selected: classes.paymentTypeButtonSelected,
              }}
              style={toggleButtonStyle}
            >
              {ui_strings?.ui_str_cash ? ui_strings.ui_str_cash : "Cash"}
            </ToggleButton>
          )}
          {enable_card_machine && (
            <ToggleButton
              value={"machine"}
              aria-label={
                ui_strings?.ui_str_machine
                  ? ui_strings.ui_str_machine
                  : "Card Machine"
              }
              selected={paymentType === "machine"}
              classes={{
                root: classes.toggleButton,
                selected: classes.paymentTypeButtonSelected,
              }}
              style={toggleButtonStyle}
            >
              {ui_strings?.ui_str_machine
                ? ui_strings.ui_str_machine
                : "Card Machine"}
            </ToggleButton>
          )}
          {payment_enabled && (
            <ToggleButton
              value={"card"}
              aria-label={
                ui_strings?.ui_str_card
                  ? ui_strings.ui_str_card
                  : "Online Payment"
              }
              selected={paymentType === "card"}
              classes={{
                root: classes.toggleButton,
                selected: classes.paymentTypeButtonSelected,
              }}
              style={toggleButtonStyle}
            >
              {ui_strings?.ui_str_card
                ? ui_strings.ui_str_card
                : "Online Payment"}
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </div>
    )
  );
}
