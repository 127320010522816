import {
  card,
  container,
  main,
  mainRaised,
} from "assets/jss/material-kit-pro-react";

const PaymentStyle = {
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised,
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 90,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    ...card,
    margin: "10px 0",
  },
  loader: {
    textAlign: "center",
  },
  orderField: {
    width: "90%",
    textAlign: "center",
  },
  menuButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },
  menuButton: {
    marginTop: 30,
    marginBottom: 5,
  },
};

export default PaymentStyle;
