import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { isValidNumber } from "libphonenumber-js";

function FormInput(props) {
  const { control, formState } = useFormContext();
  const {
    name,
    label,
    required,
    helperText,
    placeholder,
    maxLength,
    rows,
    type,
    InputProps,
    InputLabelProps,
    country,
  } = props;

  const [message, setMessage] = useState(helperText);

  useEffect(() => {
    if (formState.errors && formState.errors.hasOwnProperty(name)) {
      setMessage(formState.errors[name].message);
    } else {
      setMessage(helperText);
    }
  }, [formState, name, helperText]);

  return (
    <Controller
      name={name}
      render={({ value, onChange }, { invalid }) => (
        <TextField
          label={label}
          value={value}
          onChange={onChange}
          multiline={!!rows}
          minRows={rows}
          error={invalid}
          type={type || "text"}
          fullWidth={true}
          helperText={message}
          InputProps={InputProps}
          InputLabelProps={{
            className: required ? "required-label" : "",
            required: required || false,
            ...InputLabelProps,
          }}
          placeholder={placeholder}
          required={required}
        />
      )}
      control={control}
      defaultValue={""}
      rules={{
        required: { value: required, message: `${label} is a required field` },
        pattern:
          type === "email"
            ? {
                value: /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
                message: "Invalid email address",
              }
            : null,
        min: {
          value: InputProps?.inputProps?.min,
          message: `${label} requires minimum value of ${InputProps?.inputProps?.min}`,
        },
        max: {
          value: InputProps?.inputProps?.max,
          message: `${label} requires maximum value of ${InputProps?.inputProps?.max}`,
        },
        maxLength: maxLength
          ? {
              value: maxLength,
              message: `${label} can not have more than ${maxLength} characters`,
            }
          : null,
        validate: (value) => {
          if (type === "tel" && value) {
            if (isValidNumber(value, country)) {
              return true;
            } else {
              return `Please enter valid phone number`;
            }
          }
          return true;
        },
      }}
    />
  );
}

export default FormInput;
