import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player/lazy";
import classNames from "classnames";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProductStyle from "./ProductStyle";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  convertNumerals,
  currency_symbol,
  flat,
  fontName,
} from "../../../constants/utils";
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import * as math from "mathjs";
import { addCart } from "../../../store/actions/cart";
import { Images } from "../../../constants";
import CustomInput from "../../CustomInput/CustomInput";
import { css } from "@emotion/css";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import Badge from "../../Badge/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import chefRecommends from "../../../assets/img/emenu/chef-recommends.png";
import QuantityButton from "../Quantity/QuantityButton";
import ProductPricing from "../Pricing/ProductPricing";
import VariationPricing from "../Pricing/VariationPricing";
import axios from "axios";
import { APP_URL } from "../../../constants/Api";
import MuiAlert from "@material-ui/lab/Alert";
import { Collapse } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(ProductStyle);
// install Swiper components
SwiperCore.use([Navigation, Pagination]);

const ModalTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const dialogContentStyles = (theme) => ({
  root: {
    paddingTop: "0 !important",
    padding: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
    backgroundColor: "black",
  },
});

const CGPFormControlLabel = withStyles((theme) => ({
  root: {
    alignItems: "flex-start",
    marginBottom: 10,
  },
}))(FormControlLabel);

const CGPCheckbox = withStyles((theme) => ({
  root: {
    padding: "0 10px",
  },
}))(Checkbox);

const DialogContent = withStyles(dialogContentStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogContent className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={"small"}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
      {children}
    </MuiDialogContent>
  );
});

export default function Product(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [special_instructions, setSpecialInstructions] = useState("");
  const { currentProduct, displayVariations } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { selected_language, menuData } = menuInfo;
  const {
    currency,
    currency_symbol: cSymbol,
    display_currency,
    price_decimal_places,
    product_footer_text,
    enable_adding_same_item_cart,
    addtocart_redirect,
    ui_strings,
    properties,
    variations,
    chef_recommends_image,
    enable_display_variation_prices,
    theme_data,
    enable_product_comments,
    taxes,
    display_prices_with_tax,
    default_product_image,
    menu_country,
    enable_arabic_numerals,
    enable_zero_price_item,
  } = menuData;
  const defaultProductImage = default_product_image?.resized;
  const cartInfo = useSelector((state) => state.cartInfo);

  const { products, afActiveOrder, afCommonOrder } = cartInfo;

  const [addingItemToOrder, setAddingItemToOrder] = useState(false);
  const [showAddToCartSnackbar, setShowAddToCartSnackbar] = useState(false);

  const addedToCart =
    products?.filter((e) => e.id === currentProduct.id).length > 0;

  const [qty, setQty] = useState(1);

  const { product_variations, combo_groups, enable_combo } = currentProduct;

  // Add selected field to each product in combo group
  const [productComboGroups, setProductComboGroups] = useState(
    combo_groups
      ? combo_groups.map((combo) => {
          return {
            ...combo,
            products: combo.products.map((product) => ({
              ...product,
              selected: false,
              product_variations: product.product_variations
                ? variations
                    .filter((v) =>
                      [
                        ...new Set(
                          product.product_variations.map((pv) => pv.variation)
                        ),
                      ].includes(v.id)
                    )
                    .map((v) => ({
                      ...v,
                      values: v.values.filter((vv) =>
                        product.product_variations.find(
                          (pv) => pv.variation_value === vv.id
                        )
                      ),
                    }))
                    .map((v) => ({
                      ...v,
                      values: v.values.map((vv) => ({
                        ...product.product_variations.find(
                          (pv) => pv.variation_value === vv.id && pv
                        ),
                        name: vv.value,
                        selected: false,
                      })),
                    }))
                : [],
            })),
          };
        })
      : []
  );

  const [productVariations, setProductVariations] = useState(
    product_variations
      ? variations
          .filter((v) =>
            [...new Set(product_variations.map((pv) => pv.variation))].includes(
              v.id
            )
          )
          .map((v) => ({
            ...v,
            values: v.values.filter((vv) =>
              product_variations.find((pv) => pv.variation_value === vv.id)
            ),
          }))
          .map((v) => ({
            ...v,
            values: v.values.map((vv) => ({
              ...product_variations.find(
                (pv) => pv.variation_value === vv.id && pv
              ),
              name: vv.value,
              selected: false,
            })),
          }))
      : []
  );

  const [requiredVariationsSet, setRequiredVariationsSet] = useState(false);
  const [requiredCombosSet, setRequiredCombosSet] = useState(
    !currentProduct?.enable_combo
  );

  const [price, setPrice] = useState(currentProduct.price);
  const [discountedPrice, setDiscountedPrice] = useState(
    currentProduct.discount > 0
      ? (
          currentProduct.price -
          (currentProduct.price * currentProduct.discount) / 100
        ).toFixed(3)
      : 0
  );
  const [singlePrice, setSinglePrice] = useState(price);
  const [singleDiscountedPrice, setSingleDiscountedPrice] = useState(
    discountedPrice
  );

  const displayVariationPrice = useCallback((product) => {
    return !!product.product_variations.some(
      (variation) => variation.display_price_product === true
    );
  }, []);

  const getTaxes = useCallback(
    (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    },
    [display_prices_with_tax, taxes]
  );

  const getVariationPrice = useCallback(
    (variationPrice) => {
      let taxes;
      const price = parseFloat(variationPrice);
      const discountedPrice =
        parseFloat(variationPrice) -
        (parseFloat(variationPrice) * currentProduct.discount) / 100;
      if (currentProduct.discount > 0) {
        taxes = getTaxes(discountedPrice);
        return convertNumerals(
          (parseFloat(discountedPrice) + taxes).toFixed(price_decimal_places),
          selected_language,
          menu_country,
          enable_arabic_numerals,
          price_decimal_places
        );
      } else {
        taxes = getTaxes(price);
        return convertNumerals(
          (parseFloat(price) + taxes).toFixed(price_decimal_places),
          selected_language,
          menu_country,
          enable_arabic_numerals,
          price_decimal_places
        );
      }
    },
    [
      currentProduct.discount,
      enable_arabic_numerals,
      getTaxes,
      menu_country,
      price_decimal_places,
      selected_language,
    ]
  );

  useEffect(() => {
    // Update product price and discounted  based on combo group variation selection
    let price = parseFloat(currentProduct.price);
    let discountedPrice =
      currentProduct.discount > 0
        ? currentProduct.price -
          (currentProduct.price * currentProduct.discount) / 100
        : 0;

    productComboGroups.forEach(function (cg) {
      cg.products.forEach(function (cgp) {
        // make sure to calculate price for selected product's variations only
        // if product is not selected, then don't calculate its variation price
        if (!cgp.selected) return;

        let selectedVariations = flat(
          cgp.product_variations.map((v) => v.values)
        ).filter((v) => v.selected);
        selectedVariations.forEach(function (sv) {
          price = math
            .evaluate(`${parseFloat(price)} ${sv.math} ${parseFloat(sv.price)}`)
            .toFixed(3);
          discountedPrice =
            currentProduct.discount > 0
              ? math
                  .evaluate(
                    `${parseFloat(discountedPrice)} ${sv.math} ${parseFloat(
                      sv.price
                    )} - ${parseFloat(sv.price)} * ${
                      currentProduct.discount
                    }/100`
                  )
                  .toFixed(3)
              : 0;
        });
      });
    });
    setSinglePrice(parseFloat(price) + getTaxes(price));
    currentProduct.discount > 0 &&
      setSingleDiscountedPrice(
        parseFloat(discountedPrice) + getTaxes(discountedPrice)
      );
    setPrice((parseFloat(price) + getTaxes(price)) * qty);
    currentProduct.discount > 0 &&
      setDiscountedPrice(
        (parseFloat(discountedPrice) + getTaxes(discountedPrice)) * qty
      );

    // Check if all required combos are selected
    if (productComboGroups.length > 0) {
      const requiredCombosSelected = productComboGroups.every(
        (rc) => rc.products.find((p) => p.selected) !== undefined
      );
      setRequiredCombosSet(requiredCombosSelected);
    } else {
      setRequiredCombosSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productComboGroups]);

  useEffect(() => {
    // Update the product price and discounted based on variation selection
    let price = parseFloat(currentProduct.price);
    let discountedPrice =
      currentProduct.discount > 0
        ? currentProduct.price -
          (currentProduct.price * currentProduct.discount) / 100
        : 0;

    let selectedVariations = flat(
      productVariations.map((v) => v.values)
    ).filter((v) => v.selected);

    selectedVariations.forEach(function (sv) {
      price = math
        .evaluate(`${parseFloat(price)} ${sv.math} ${parseFloat(sv.price)}`)
        .toFixed(3);
      discountedPrice =
        currentProduct.discount > 0
          ? math
              .evaluate(
                `${parseFloat(discountedPrice)} ${sv.math} ${parseFloat(
                  sv.price
                )} - ${parseFloat(sv.price)} * ${currentProduct.discount}/100`
              )
              .toFixed(3)
          : 0;
    });

    setSinglePrice(parseFloat(price) + getTaxes(price));
    currentProduct.discount > 0 &&
      setSingleDiscountedPrice(
        parseFloat(discountedPrice) + getTaxes(discountedPrice)
      );

    setPrice((parseFloat(price) + getTaxes(price)) * qty);
    currentProduct.discount > 0 &&
      setDiscountedPrice(
        (parseFloat(discountedPrice) + getTaxes(discountedPrice)) * qty
      );

    // Check if all required variations are selected
    const requiredVariations = productVariations.filter(
      (v) => v.required === true
    );
    if (requiredVariations.length > 0) {
      const requiredVariationsSelected = requiredVariations.every(
        (rv) => rv.values.find((vv) => vv.selected) !== undefined
      );
      setRequiredVariationsSet(requiredVariationsSelected);
    } else {
      setRequiredVariationsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariations, qty]);

  const classes = useStyles();

  const handleVariationSelection = useCallback(
    (event) => {
      const elementsIndex = productVariations.findIndex(
        (element) => element.name === event.target.name
      );
      let newProductVariations = [...productVariations];
      newProductVariations[elementsIndex] = {
        ...newProductVariations[elementsIndex],
        values: newProductVariations[elementsIndex].values.map((vv) =>
          vv.variation_value === event.target.value
            ? { ...vv, selected: !vv.selected }
            : { ...vv, selected: false }
        ),
      };
      // Set uniqueVariation state
      setProductVariations(newProductVariations);
      // console.log(newProductVariations);
    },
    [productVariations]
  );

  const handleMultipleVariationSelection = useCallback(
    (variation_value, index) => (event) => {
      let newProductVariations = [...productVariations];
      newProductVariations[index] = {
        ...newProductVariations[index],
        values: newProductVariations[index].values.map((vv) =>
          vv.variation_value === variation_value
            ? { ...vv, selected: !vv.selected }
            : vv
        ),
      };
      // Set uniqueVariation state
      setProductVariations(newProductVariations);
      // console.log(newProductVariations);
    },
    [productVariations]
  );

  const handleComboGroupSelection = useCallback(
    (combo_group_id, product_id) => {
      let newProductComboGroups = [...productComboGroups];
      const comboGroupIndex = newProductComboGroups.findIndex(
        (cg) => cg.id === combo_group_id
      );

      // reset all selected first
      newProductComboGroups[comboGroupIndex].products = newProductComboGroups[
        comboGroupIndex
      ].products.map((p) => ({ ...p, selected: false }));

      const productIndex = newProductComboGroups[
        comboGroupIndex
      ].products.findIndex((p) => p.id === product_id);
      newProductComboGroups[comboGroupIndex].products[
        productIndex
      ].selected = !newProductComboGroups[comboGroupIndex].products[
        productIndex
      ].selected;
      setProductComboGroups(newProductComboGroups);
    },
    [productComboGroups]
  );

  const handleComboVariationSelection = useCallback(
    (variation_value, index, cg_id, cgp_id) => (event) => {
      let newProductComboGroups = [...productComboGroups];
      const comboGroupIndex = newProductComboGroups.findIndex(
        (cg) => cg.id === cg_id
      );
      const productIndex = newProductComboGroups[
        comboGroupIndex
      ].products.findIndex((p) => p.id === cgp_id);

      newProductComboGroups[comboGroupIndex].products[
        productIndex
      ].product_variations[index].values = newProductComboGroups[
        comboGroupIndex
      ].products[productIndex].product_variations[index].values.map((vv) =>
        vv.variation_value === variation_value
          ? { ...vv, selected: !vv.selected }
          : vv
      );
      // Set uniqueVariation state
      setProductComboGroups(newProductComboGroups);
    },
    [productComboGroups]
  );

  const renderProductImage = useCallback(
    (prod, modal = false) => {
      return (
        <div
          className={classNames(classes.carousel)}
          style={{
            marginTop: modal ? 0 : 70,
            marginBottom: modal ? 20 : 0,
          }}
        >
          {prod.video_file ? (
            <div className={classes.videoWrapper}>
              <ReactPlayer
                url={prod.video_file}
                playing
                loop
                playsinline
                muted
                controls={false}
                width={"100%"}
                height={"100%"}
                style={{ position: "absolute", top: 0, left: 0 }}
                config={{
                  file: {
                    attributes: {
                      preload: "auto",
                      disablePictureInPicture: true,
                      poster:
                        prod.images.length > 0
                          ? prod.images[0].image.product
                          : defaultProductImage
                          ? defaultProductImage
                          : Images.PlaceHolderProduct.image,
                    },
                  },
                }}
                className={classNames("swiper-no-swiping")}
              />
            </div>
          ) : prod.images?.length > 1 ? (
            <Swiper
              simulateTouch={false}
              slidesPerView={1}
              navigation={{
                nextEl: null,
                prevEl: null,
              }}
              pagination={{ clickable: true }}
            >
              {prod.images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={image.image.product}
                    className={classNames(
                      classes.productImage,
                      prod.images?.length === 1 ? "swiper-no-swiping" : null
                    )}
                    alt={prod.name}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : prod.images?.length === 1 ? (
            <img
              src={prod.images[0].image.product}
              className={classNames(classes.productImage)}
              alt={prod.name}
            />
          ) : (
            <img
              src={
                defaultProductImage
                  ? defaultProductImage
                  : Images.PlaceHolderProduct.image
              }
              className={classNames(classes.productImage)}
              alt={prod.name}
            />
          )}
        </div>
      );
    },
    [classes, defaultProductImage]
  );

  const formLabelStyle = useMemo(() => {
    return {
      fontSize: Number(
        theme_data?.product_screen_variation_dropdown_title_font_size
      ),
      color: theme_data?.product_screen_variation_dropdown_title_text_color,
      ...fontName(
        theme_data?.product_screen_variation_dropdown_title_font_family
      ),
    };
  }, [theme_data]);

  const formLabelHelpStyle = useMemo(() => {
    return {
      fontSize: 12,
      color: "grey",
      marginTop: 8,
      marginBottom: 8,
    };
  }, []);

  const requiredStyle = useMemo(() => {
    return {
      color: "red",
      fontSize: "12px",
      lineHeight: "12px",
      marginTop: 8,
      marginBottom: 8,
    };
  }, []);

  const variationTextStyle = useMemo(() => {
    return {
      color:
        theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
      fontSize: Number(
        theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
      ),
      ...fontName(
        theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
      ),
    };
  }, [theme_data]);

  const variationSelectStyle = useMemo(() => {
    return {
      backgroundColor: theme_data?.product_screen_variation_dropdown_btn_color,
      color:
        theme_data?.product_screen_variation_dropdown_btn_selected_text_color,
      fontSize: Number(
        theme_data?.product_screen_variation_dropdown_btn_selected_font_size
      ),
      ...fontName(
        theme_data?.product_screen_variation_dropdown_btn_selected_font_family
      ),
      padding: "0 10px",
    };
  }, [theme_data]);

  const propertyBadgeStyle = useMemo(() => {
    return {
      backgroundColor: theme_data?.product_screen_property_bg_color,
      color: theme_data?.product_screen_property_text_color,
      fontSize: Number(theme_data?.product_screen_property_font_size),
      ...fontName(theme_data?.product_screen_property_font_family),
    };
  }, [theme_data]);

  const moreInfoStyle = useMemo(() => {
    return {
      color: "#3295d1",
      marginLeft: 10,
      display: "inline-block",
    };
  }, []);

  const [tooltips, setTooltips] = useState([]);

  const handleTooltip = useCallback(
    (value) => {
      if (value) {
        const currentIndex = tooltips.indexOf(value);
        const newTooltips = [...tooltips];

        if (currentIndex === -1) {
          newTooltips.push(value);
        } else {
          newTooltips.splice(currentIndex, 1);
        }
        setTooltips(newTooltips);
      } else {
        setTooltips([]);
      }
    },
    [tooltips]
  );

  const renderProperties = useCallback(
    (props, small = false) => {
      return (
        <div
          className={classes.propertyStyle}
          style={{
            justifyContent: small ? "flex-start" : "center",
            marginTop: 2,
            marginBottom: small ? 2 : 20,
          }}
        >
          {props.map((property) => (
            <div key={property}>
              {properties.find((x) => x.id === property).image?.icon ? (
                <ClickAwayListener
                  mouseEvent={false}
                  onClickAway={() => handleTooltip()}
                >
                  <div>
                    <Tooltip
                      title={properties.find((x) => x.id === property).name}
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltip(property)}
                      open={tooltips.indexOf(property) !== -1}
                      TransitionComponent={Zoom}
                    >
                      <img
                        className={classes.propertyIcon}
                        style={{
                          width: small ? 24 : 32,
                          height: small ? 24 : 32,
                          marginLeft: small ? 0 : 5,
                          marginRight: 5,
                        }}
                        src={
                          properties.find((x) => x.id === property).image.icon
                        }
                        onClick={() => handleTooltip(property)}
                        alt={properties.find((x) => x.id === property).name}
                      />
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              ) : (
                <Badge
                  style={{
                    ...propertyBadgeStyle,
                    paddingLeft: small ? 6 : 12,
                    paddingRight: small ? 6 : 12,
                  }}
                >
                  {properties.find((x) => x.id === property).name}
                </Badge>
              )}
            </div>
          ))}
        </div>
      );
    },
    [classes, handleTooltip, properties, propertyBadgeStyle, tooltips]
  );

  const renderVariations = useCallback(() => {
    return (
      <div>
        {productVariations.map((pv, index) => (
          <div style={{ paddingBottom: 20 }} key={index}>
            <FormControl fullWidth>
              {pv.multiple ? (
                <>
                  <FormLabel component="legend" style={formLabelStyle}>
                    <div>
                      {pv.name}
                      {pv.required ? (
                        <>
                          <span style={{ color: "red" }}> * </span>
                          <div style={requiredStyle}>Required</div>
                        </>
                      ) : null}
                      {pv.max_choices_allowed > 0 ? (
                        <div style={formLabelHelpStyle}>
                          Choose upto {pv.max_choices_allowed}
                          {pv.max_choices_allowed > 1 ? ` options` : ` option`}
                        </div>
                      ) : null}
                    </div>
                  </FormLabel>
                  <FormGroup>
                    {pv.values.map((variation, pv_index) => (
                      <FormControlLabel
                        key={pv_index}
                        control={
                          <Checkbox
                            checked={
                              pv.values.find(
                                (v) =>
                                  v.variation_value ===
                                  variation.variation_value
                              )
                                ? pv.values.find(
                                    (v) =>
                                      v.variation_value ===
                                      variation.variation_value
                                  ).selected
                                : false
                            }
                            disabled={
                              pv.max_choices_allowed > 0 &&
                              pv.values.filter((pvv) => pvv.selected).length >=
                                pv.max_choices_allowed &&
                              pv.values
                                .filter((pvv) => !pvv.selected)
                                .find(
                                  (v) =>
                                    v.variation_value ===
                                    variation.variation_value
                                ) !== undefined
                            }
                            onChange={handleMultipleVariationSelection(
                              variation.variation_value,
                              index
                            )}
                            value={variation.name}
                          />
                        }
                        label={
                          <Typography style={variationTextStyle}>
                            {variation.name}
                            {` ${
                              variation.price > 0 &&
                              enable_display_variation_prices
                                ? `(${variation.math}
                    ${
                      display_currency
                        ? cSymbol
                          ? cSymbol
                          : currency_symbol(currency)
                        : ``
                    }
                    ${getVariationPrice(variation.price)})`
                                : ``
                            }`}
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                </>
              ) : (
                <>
                  <InputLabel
                    htmlFor={`${pv.name}`}
                    className={classes.selectShrink}
                    style={{
                      ...formLabelStyle,
                      zIndex: 1,
                      padding: "0 10px",
                      pointerEvents: "none",
                    }}
                  >
                    {pv.name}
                    {pv.required ? "*" : null}
                  </InputLabel>
                  <Select
                    style={variationSelectStyle}
                    className={css`
                      &::after {
                        border-bottom: 2px solid
                          ${theme_data?.product_screen_variation_dropdown_btn_selected_text_color};
                      }
                    `}
                    value={
                      pv.values.find((v) => v.selected)
                        ? pv.values.find((v) => v.selected).variation_value
                        : ""
                    }
                    onChange={handleVariationSelection}
                    inputProps={{
                      name: `${pv.name}`,
                      id: `${pv.id}`,
                    }}
                  >
                    {!pv.required && (
                      <MenuItem style={variationTextStyle}>{"---"}</MenuItem>
                    )}
                    {pv.values.map((variation) => (
                      <MenuItem
                        key={variation.variation_value}
                        style={variationTextStyle}
                        value={variation.variation_value}
                      >
                        {variation.name}
                        {` ${
                          variation.price > 0 && enable_display_variation_prices
                            ? `(${variation.math}
                    ${
                      display_currency
                        ? cSymbol
                          ? cSymbol
                          : currency_symbol(currency)
                        : ``
                    }
                    ${getVariationPrice(variation.price)})`
                            : ``
                        }`}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </FormControl>
          </div>
        ))}
      </div>
    );
  }, [
    classes.selectShrink,
    currency,
    display_currency,
    enable_display_variation_prices,
    formLabelHelpStyle,
    formLabelStyle,
    getVariationPrice,
    handleMultipleVariationSelection,
    handleVariationSelection,
    productVariations,
    requiredStyle,
    theme_data?.product_screen_variation_dropdown_btn_selected_text_color,
    variationSelectStyle,
    variationTextStyle,
    cSymbol,
  ]);

  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [moreInfoCGP, setMoreInfoCGP] = useState(undefined);

  const handleCloseMoreInfo = () => {
    setOpenMoreInfo(false);
  };

  const handleOpenMoreInfo = (cgp) => {
    setOpenMoreInfo(true);
    setMoreInfoCGP(cgp);
  };

  const renderCombos = useCallback(() => {
    return (
      <div>
        {productComboGroups?.map((cg, index) => (
          <div style={{ paddingBottom: 32 }} key={index}>
            <FormControl fullWidth>
              <FormLabel component="legend" style={formLabelStyle}>
                <div>
                  {cg.name}
                  <>
                    <span style={{ color: "red" }}> * </span>
                    <div style={requiredStyle}>Required</div>
                  </>
                </div>
              </FormLabel>
              <FormGroup aria-label={cg.name} name={`group-${cg.id}`}>
                {cg.products?.map((cgp, cgp_index) => {
                  const cgp_checked =
                    productComboGroups
                      .find((c) => c.id === cg.id)
                      .products.find((p) => p.id === cgp.id)?.selected || false;
                  return (
                    <CGPFormControlLabel
                      key={cgp_index}
                      control={
                        <CGPCheckbox
                          disabled={cgp.out_of_stock}
                          checked={cgp_checked}
                          value={cgp.id}
                          onChange={() =>
                            handleComboGroupSelection(cg.id, cgp.id)
                          }
                        />
                      }
                      label={
                        <div
                          style={{
                            ...variationTextStyle,
                            color: cgp.out_of_stock
                              ? "gray"
                              : theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                          }}
                        >
                          {cgp.name}
                          {(cgp.images?.length > 0 || cgp.description) && (
                            <i
                              className="fa fa-info-circle"
                              style={moreInfoStyle}
                              onClick={() => handleOpenMoreInfo(cgp)}
                            />
                          )}

                          {cgp.out_of_stock ? (
                            <div style={{ fontSize: 10 }}>Out of Stock</div>
                          ) : (
                            cgp.properties?.length > 0 && (
                              <div>
                                {renderProperties(cgp.properties, true)}
                              </div>
                            )
                          )}

                          {cgp_checked && cgp.product_variations.length > 0 && (
                            <div style={{ marginTop: 20 }}>
                              {cgp.product_variations?.map((pv, index) => (
                                <div style={{ paddingBottom: 20 }} key={index}>
                                  <FormControl fullWidth>
                                    <FormLabel
                                      component="legend"
                                      style={{
                                        ...formLabelStyle,
                                        fontSize: 14,
                                      }}
                                    >
                                      <div>
                                        {pv.name}
                                        {pv.required ? (
                                          <>
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *{" "}
                                            </span>
                                            <div style={requiredStyle}>
                                              Required
                                            </div>
                                          </>
                                        ) : null}
                                        {pv.max_choices_allowed > 0 ? (
                                          <div style={formLabelHelpStyle}>
                                            Choose upto {pv.max_choices_allowed}
                                            {pv.max_choices_allowed > 1
                                              ? ` options`
                                              : ` option`}
                                          </div>
                                        ) : null}
                                      </div>
                                    </FormLabel>
                                    <FormGroup>
                                      {pv.values.map((variation, pv_index) => (
                                        <FormControlLabel
                                          key={pv_index}
                                          control={
                                            <Checkbox
                                              checked={
                                                pv.values.find(
                                                  (v) =>
                                                    v.variation_value ===
                                                    variation.variation_value
                                                )?.selected || false
                                              }
                                              disabled={
                                                pv.max_choices_allowed > 0 &&
                                                pv.values.filter(
                                                  (pvv) => pvv.selected
                                                ).length >=
                                                  pv.max_choices_allowed &&
                                                pv.values
                                                  .filter(
                                                    (pvv) => !pvv.selected
                                                  )
                                                  .find(
                                                    (v) =>
                                                      v.variation_value ===
                                                      variation.variation_value
                                                  ) !== undefined
                                              }
                                              onChange={handleComboVariationSelection(
                                                variation.variation_value,
                                                index,
                                                cg.id,
                                                cgp.id
                                              )}
                                              value={variation.name}
                                            />
                                          }
                                          label={
                                            <Typography
                                              style={{
                                                ...variationTextStyle,
                                                fontSize: 13,
                                              }}
                                            >
                                              {variation.name}
                                              {` ${
                                                variation.price > 0 &&
                                                enable_display_variation_prices
                                                  ? `(${variation.math}
                    ${
                      display_currency
                        ? cSymbol
                          ? cSymbol
                          : currency_symbol(currency)
                        : ``
                    }
                    ${getVariationPrice(variation.price)})`
                                                  : ``
                                              }`}
                                            </Typography>
                                          }
                                        />
                                      ))}
                                    </FormGroup>
                                  </FormControl>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      }
                    />
                  );
                })}
              </FormGroup>
            </FormControl>
          </div>
        ))}
      </div>
    );
  }, [
    productComboGroups,
    formLabelStyle,
    requiredStyle,
    variationTextStyle,
    theme_data,
    moreInfoStyle,
    renderProperties,
    handleComboGroupSelection,
    formLabelHelpStyle,
    handleComboVariationSelection,
    enable_display_variation_prices,
    display_currency,
    currency,
    getVariationPrice,
    cSymbol,
  ]);

  const closeAddToCartSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAddToCartSnackbar(false);
  };

  const addItemToOrder = (item) => {
    setAddingItemToOrder(true);

    // this is done for this special view, sending only variation IDs
    let item_variations = [];
    item.variations.map((variation) =>
      variation.values
        .filter((v) => v.selected)
        .map((vv) => (vv.id ? item_variations.push(vv.id) : null))
    );

    const line_item = {
      product: item.id,
      product_sku: item.sku,
      product_name: item.name,
      product_variation: item.variations
        .map(
          (variation) =>
            `${variation.name}: ${variation.values
              .filter((v) => v.selected)
              .map((v) => v.name)
              .join(", ")}`
        )
        .join("\n    "),
      variations: item_variations,
      combo_groups: item.combo_groups,
      product_price: parseFloat(item.price).toFixed(price_decimal_places),
      product_price_currency: currency,
      quantity: item.qty,
      special_instructions: item.special_instructions,
      cart_uuid: item.uuid,
    };

    axios
      .patch(
        `${APP_URL}/en/api/order/${afActiveOrder}/`,
        JSON.stringify({
          line_items: [line_item],
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setAddingItemToOrder(false);
        history.push({
          pathname: `/order/${response.data.uuid}`,
          state: { order: response.data },
        });
      })
      .catch((error) => {
        // Error
        setAddingItemToOrder(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          setShowAddToCartSnackbar(true);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        //console.log(error.config);
      });
  };

  const handleAddToCart = () => {
    if (requiredVariationsSet && requiredCombosSet) {
      const clean_variations = productVariations.filter(
        (v) => v.values.find((vv) => vv.selected) !== undefined
      );

      if (!!afActiveOrder) {
        const item = {
          uuid: uuidv4(),
          id: currentProduct.id,
          category: currentProduct.category,
          name: currentProduct.name,
          sku: currentProduct.sku,
          image: currentProduct.images[0]
            ? currentProduct.images[0].image
            : null,
          price:
            currentProduct.discount > 0 ? singleDiscountedPrice : singlePrice,
          qty: qty,
          special_instructions: special_instructions,
          variations: clean_variations,
          combo_groups: productComboGroups,
          max_qty: currentProduct.pos_available_qty,
        };
        addItemToOrder(item);
      } else {
        dispatch(
          addCart(
            uuidv4(),
            currentProduct.id,
            currentProduct.category,
            currentProduct.name,
            currentProduct.sku,
            currentProduct.images[0] ? currentProduct.images[0].image : null,
            currentProduct.discount > 0 ? singleDiscountedPrice : singlePrice,
            qty,
            special_instructions,
            clean_variations,
            productComboGroups,
            currentProduct.pos_available_qty
          )
        );
        window.scroll(0, 0);
        document.body.scrollTop = 0;
        if (addtocart_redirect === "cart") {
          history.push("/cart");
        } else if (addtocart_redirect === "back") {
          history.goBack();
        }
      }
    }
  };
  const productCardStyle = {
    backgroundColor: theme_data?.product_screen_description_bg_color,
  };
  const productNameStyle = {
    paddingBottom: 10,
    color: theme_data?.product_screen_product_name_text_color,
    fontSize: Number(theme_data?.product_screen_product_name_font_size),
    ...fontName(theme_data?.product_screen_product_name_font_family),
  };
  const productPriceStyle = {
    paddingBottom: 10,
    color: theme_data?.product_screen_product_price_text_color,
    fontSize: Number(theme_data?.product_screen_product_price_font_size),
    ...fontName(theme_data?.product_screen_product_price_font_family),
  };
  const productDescriptionStyle = {
    paddingBottom: 10,
    color: theme_data?.product_screen_product_description_text_color,
    fontSize: Number(theme_data?.product_screen_product_description_font_size),
    ...fontName(theme_data?.product_screen_product_description_font_family),
  };
  const footerStyle = {
    color: theme_data?.product_screen_product_description_text_color,
    ...fontName(theme_data?.product_screen_product_description_font_family),
  };
  const addToCartStyle = {
    backgroundColor: theme_data?.product_screen_add_to_cart_btn_color,
    fontSize: Number(theme_data?.product_screen_add_to_cart_font_size),
    color: theme_data?.product_screen_add_to_cart_text_color,
    ...fontName(theme_data?.product_screen_add_to_cart_font_family),
    marginTop: 20,
  };

  const handleSpecialInstructionsChange = (e) =>
    setSpecialInstructions(e.target.value);

  return (
    <div>
      {renderProductImage(currentProduct)}
      <div className={classNames(classes.toolbar, classes.main)}>
        <div
          className={classNames(classes.container, classes.mainRaised)}
          style={productCardStyle}
        >
          {currentProduct.recommend ? (
            <div className={classes.chefRecommends}>
              <img
                src={
                  chef_recommends_image?.resized
                    ? chef_recommends_image.resized
                    : chefRecommends
                }
                alt={currentProduct.name}
              />
            </div>
          ) : null}

          <div className={classes.productTitle} style={productNameStyle}>
            {currentProduct.name}
          </div>

          <div className={classes.productPrice} style={productPriceStyle}>
            {price > 0 && displayVariationPrice(currentProduct) && (
              <VariationPricing
                product={currentProduct}
                price={price}
                discountedPrice={discountedPrice}
                view={"product"}
              />
            )}

            {price > 0 && !displayVariationPrice(currentProduct) && (
              <ProductPricing
                product={currentProduct}
                price={price}
                discountedPrice={discountedPrice}
              />
            )}
          </div>

          {currentProduct.properties.length > 0
            ? renderProperties(currentProduct.properties)
            : null}

          <div
            className={classes.productDescription}
            style={productDescriptionStyle}
            dangerouslySetInnerHTML={{ __html: currentProduct.description }}
          />

          <div className={classes.variations}>
            {enable_combo && renderCombos()}
          </div>

          <div className={classes.variations}>
            {displayVariations && renderVariations()}
          </div>

          {menuInfo.menuData.enable_order &&
          menuInfo.isOpen &&
          (price > 0 || enable_zero_price_item) ? (
            <div className={`add-to-cart-${currentProduct.id}`}>
              {addedToCart && !enable_adding_same_item_cart
                ? null
                : enable_product_comments && (
                    <CustomInput
                      id={"special_instructions"}
                      labelText={
                        ui_strings?.ui_str_special_instructions
                          ? ui_strings.ui_str_special_instructions
                          : "Special Instructions"
                      }
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        value: special_instructions,
                        onChange: handleSpecialInstructionsChange,
                        multiline: true,
                        rows: 2,
                        placeholder: ui_strings?.ui_str_special_instructions_placeholder
                          ? ui_strings.ui_str_special_instructions_placeholder
                          : "Example: No pepper / sugar / salt please",
                        className: css`
                          &::after {
                            border-bottom: 2px solid
                              ${theme_data?.cart_screen_form_input_border_color};
                          }
                        `,
                      }}
                    />
                  )}
              {!currentProduct.enable_combo && (
                <QuantityButton
                  qty={qty}
                  setQty={setQty}
                  maxQty={currentProduct?.pos_available_qty}
                />
              )}

              <Collapse in={showAddToCartSnackbar}>
                <MuiAlert
                  elevation={6}
                  variant={"filled"}
                  severity={"error"}
                  onClose={closeAddToCartSnackbar}
                >
                  Error adding item
                </MuiAlert>
              </Collapse>

              <Button
                className={"addToCartButton"}
                fullWidth
                disabled={
                  (addedToCart && !enable_adding_same_item_cart) ||
                  currentProduct.out_of_stock ||
                  !requiredVariationsSet ||
                  !requiredCombosSet ||
                  addingItemToOrder ||
                  afCommonOrder
                }
                style={addToCartStyle}
                onClick={handleAddToCart}
              >
                {addedToCart && !enable_adding_same_item_cart
                  ? ui_strings?.ui_str_already_added_to_cart
                    ? ui_strings.ui_str_already_added_to_cart
                    : "Already Added To Cart"
                  : currentProduct.out_of_stock
                  ? ui_strings?.ui_str_out_of_stock
                    ? ui_strings.ui_str_out_of_stock
                    : "Out of Stock"
                  : afActiveOrder && !addingItemToOrder
                  ? "Add to Order"
                  : afActiveOrder && addingItemToOrder
                  ? "Adding to Order"
                  : ui_strings?.ui_str_add_to_cart
                  ? ui_strings.ui_str_add_to_cart
                  : "Add To Cart"}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        {product_footer_text && (
          <div
            className={classes.footer}
            style={footerStyle}
            dangerouslySetInnerHTML={{ __html: product_footer_text }}
          />
        )}
      </div>
      {currentProduct.enable_combo && (
        <div>
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={openMoreInfo}
            onClose={handleCloseMoreInfo}
            aria-labelledby="combo-info"
            TransitionComponent={ModalTransition}
          >
            <DialogContent onClose={handleCloseMoreInfo}>
              {moreInfoCGP && (
                <div>
                  {renderProductImage(moreInfoCGP, true)}
                  <div
                    className={classes.productTitle}
                    style={productNameStyle}
                  >
                    {moreInfoCGP.name}
                  </div>
                  {renderProperties(moreInfoCGP.properties)}
                  <div
                    className={classes.productDescription}
                    style={productDescriptionStyle}
                    dangerouslySetInnerHTML={{
                      __html: moreInfoCGP.description,
                    }}
                  />
                </div>
              )}
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
}
