import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";

const FormCheckbox = (props) => {
  const { control, formState, getValues, setValue } = useFormContext();
  const {
    name,
    label,
    options,
    inline,
    required,
    description,
    other,
    toggle,
  } = props;

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(description);

  const [checkedValues, setCheckedValues] = useState([]);

  const [otherValue, setOtherValue] = useState("");

  const otherValueChangeHandler = (e) => {
    if (e.target.value !== "other-value") {
      setOtherValue(e.target.value);
    } else {
      setOtherValue("");
    }
  };

  useEffect(() => {
    const values = getValues(name);
    const searchStr = "other-value";
    const i = values.findIndex((val) => val.includes(searchStr));

    if (i > -1) {
      if (otherValue !== "") {
        values[i] = `${searchStr}: ${otherValue}`;
      } else {
        values[i] = searchStr;
      }
      setValue(name, values);
    }
    // eslint-disable-next-line
  }, [otherValue]);

  function handleSelect(checkedName) {
    if (checkedName !== "") {
      const newNames = checkedValues?.includes(checkedName)
        ? checkedValues?.filter((name) => name !== checkedName)
        : [...(checkedValues ?? []), checkedName];
      setCheckedValues(newNames);
      return newNames;
    }
    return checkedValues ?? [];
  }

  useEffect(() => {
    if (formState.errors && formState.errors.hasOwnProperty(name)) {
      setMessage(formState.errors[name].message);
      setIsError(true);
    } else {
      setMessage(description);
      setIsError(false);
    }
  }, [formState, name, description, otherValue]);

  const validateValue = (value) => {
    if (required) {
      if (value.includes("other-value")) {
        return otherValue !== "" ? true : "Please enter other value";
      } else {
        return value.length > 0 ? true : `${label} is a required field`;
      }
    } else {
      if (value.includes("other-value")) {
        return otherValue !== "" ? true : "Please enter other value";
      }
    }
    return true;
  };

  return (
    <FormControl fullWidth={true} required={required} component={"fieldset"}>
      <FormLabel component={"legend"}>{label}</FormLabel>
      <FormHelperText className={isError ? "Mui-error" : ""}>
        {message}
      </FormHelperText>
      <Controller
        name={name}
        render={({ value, onChange }, { invalid }) => (
          <FormGroup id={name} name={name} value={value} row={inline}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  !toggle ? (
                    <Checkbox
                      onChange={() => onChange(handleSelect(option.value))}
                      checked={checkedValues.includes(option.value)}
                    />
                  ) : (
                    <Switch
                      onChange={() => onChange(handleSelect(option.value))}
                      checked={checkedValues.includes(option.value)}
                    />
                  )
                }
                label={option.label}
                value={option.value}
              />
            ))}
            {other ? (
              <FormControlLabel
                control={
                  !toggle ? (
                    <Checkbox
                      onChange={(e) => {
                        onChange(handleSelect("other-value"));
                        otherValueChangeHandler(e);
                      }}
                      checked={
                        checkedValues.findIndex((val) =>
                          val.includes("other-value")
                        ) > -1
                      }
                    />
                  ) : (
                    <Switch
                      onChange={(e) => {
                        onChange(handleSelect("other-value"));
                        otherValueChangeHandler(e);
                      }}
                      checked={
                        checkedValues.findIndex((val) =>
                          val.includes("other-value")
                        ) > -1
                      }
                    />
                  )
                }
                label={"Other"}
                value={"other-value"}
                disabled={!!otherValue}
                style={{ display: otherValue ? "none" : "inherit" }}
              />
            ) : null}
            {other &&
            value &&
            value.findIndex((val) => val.includes("other-value")) > -1 ? (
              <TextField
                error={invalid}
                fullWidth={true}
                label={"Other"}
                name={"other-text"}
                value={otherValue}
                onChange={(e) => {
                  onChange(handleSelect(""));
                  otherValueChangeHandler(e);
                }}
              />
            ) : null}
          </FormGroup>
        )}
        control={control}
        defaultValue={checkedValues}
        rules={{
          required: {
            value: required,
            message: `${label} is a required field`,
          },
          validate: { validateValue },
        }}
      />
    </FormControl>
  );
};

export default FormCheckbox;
