import {
  container,
  main,
  mainRaised,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const HomeStyle = (theme) => ({
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised,
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 70,
    position: "relative",
  },
  categories: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "start",
    paddingTop: 20,
    paddingBottom: 20,
  },
  homeHeaderText: {
    marginTop: 20,
    marginBottom: 10,
    lineHeight: "14px",
  },
  homeFooterText: {
    marginTop: 20,
    marginBottom: 20,
    lineHeight: "14px",
  },
  categoryTitle: {
    padding: "0.5rem",
    textAlign: "center",
  },
  title: {
    ...title,
    color: whiteColor,
  },
  card: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  categoryImage: {
    height: "auto",
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: theme.spacing(1),
  },
  modal: {
    backgroundColor: "transparent",
  },
});

export default HomeStyle;
