const theme = {
  COLORS: {
    THEME1: "#9C26B0",
    THEME2: "#FE2472",
    DARK: "#1C1C1C",
    LIGHT: "#DCDCDC",
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    SUCCESS: "#22C201",
    ERROR: "#E8060D",
  },
  FONT: "Titillium-Web-Regular",
};

const DefaultTheme = {
  EMENU: {
    WELCOME_SCREEN: {
      BACKGROUND_COLOR: theme.COLORS.BLACK,
      FORM: {
        INPUT: {
          BORDER: {
            COLOR: theme.COLORS.WHITE,
          },
          PLACEHOLDER: {
            TEXT_COLOR: theme.COLORS.WHITE,
          },
          TEXT: {
            TEXT_COLOR: theme.COLORS.WHITE,
          },
        },
      },
      LOGIN_BUTTON: {
        BUTTON_COLOR: theme.COLORS.THEME1,
        TEXT_COLOR: theme.COLORS.WHITE,
        FONT: theme.FONT,
        FONT_SIZE: 20,
      },
    },
  },
};

export default DefaultTheme;
