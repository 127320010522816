import {
  blackColor,
  card,
  container,
  hexToRgb,
  main,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

import formStyles from "views/FormPage/FormStyle";

const OrderStyle = {
  ...formStyles,
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 100,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    ...card,
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: 10,
    justifyContent: "space-between",
  },
  orderReceipt: {
    ...card,
    margin: "10px 0",
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  lineItemTable: {
    width: "100%",
  },
  menuTotalsWave: {
    width: "100%",
    marginTop: -8,
    marginBottom: 30,
    zIndex: 1,
    filter: "drop-shadow(0px 6px 4px rgba(" + hexToRgb(blackColor) + ", 0.04))",
    webkitFilter:
      "drop-shadow(0px 6px 4px rgba(" + hexToRgb(blackColor) + ", 0.04))",
  },
  formContainer: {
    backgroundColor: whiteColor,
    width: "100%",
    padding: 10,
    borderRadius: 10,
  },
  success: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  itemComboList: {
    marginBottom: 0,
    marginLeft: 10,
  },
  itemComboListItem: {
    marginLeft: 4,
    lineHeight: "1.2em",
  },
  successMessage: {
    marginTop: 20,
    textAlign: "center",
  },
};

export default OrderStyle;
