import { container, main, mainRaised } from "assets/jss/material-kit-pro-react";

const FormStyle = {
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised,
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 70,
  },
  page: {
    padding: 20,
    "& ul, & ol": {
      paddingLeft: 20,
    },
    lineHeight: "14px",
  },
  form: {
    padding: 10,
    "& .MuiGrid-container": {
      marginTop: 20,
    },
  },
  loader: {
    textAlign: "center",
    marginTop: 50,
  },
  success: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 70,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  successMessage: {
    marginTop: 20,
    textAlign: "center",
  },
};

export default FormStyle;
