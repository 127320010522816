import {
  blackColor,
  container,
  grayColor,
  hexToRgb,
  main,
  mainRaised,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";
import checkboxStyle from "assets/jss/material-kit-pro-react/customCheckboxRadioSwitchStyle.js";
import badgeStyle from "assets/jss/material-kit-pro-react/components/badgeStyle.js";

const CategoryStyle = (theme) => ({
  ...modalStyle(theme),
  ...checkboxStyle,
  ...badgeStyle,
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised,
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 70,
    position: "relative",
  },
  categories: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "start",
    paddingTop: 20,
    paddingBottom: 20,
  },
  categoryTitle: {
    padding: "0.5rem",
    textAlign: "center",
  },
  products: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around",
    alignContent: "start",
    paddingTop: 20,
    paddingBottom: 20,
  },
  productsList: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    paddingTop: 20,
    paddingBottom: 20,
  },
  productTitle: {
    padding: "0.5rem",
    textAlign: "center",
  },
  productDetailsList: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    paddingLeft: 10,
    width: "100%",
  },
  productListItem: {
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
  },
  productTitleList: {
    padding: "0.5rem",
  },
  productImageList: {
    width: "30%",
    height: "100%",
  },
  ProductTitleWithCart: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  title: {
    ...title,
    color: whiteColor,
  },
  card: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  chefRecommends: {
    width: 20,
    height: 20,
    margin: "auto",
    marginTop: -20,
    position: "absolute",
    zIndex: 2,
    left: "45%",
    "& > img": {
      width: "100%",
    },
  },
  promotions: {
    marginLeft: -15,
    marginRight: -15,
  },
  categoryDescription: {
    paddingTop: 20,
    marginBottom: -20,
    lineHeight: "14px",
  },
  categoryImage: {
    height: "auto",
  },
  promoImage: {
    width: "100%",
  },
  dishProperties: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  specialCategoryName: {
    textAlign: "center",
    paddingTop: 10,
  },
  scrollCategoryNav: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    marginBottom: 20,
    overflowX: "auto",
    zIndex: 20,
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
  },
  stickyNav: {
    position: "fixed",
    top: 70,
    left: 0,
    right: 0,
    zIndex: 1001,
    backdropFilter: "blur(20px)",
    padding: "10px 15px",
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(blackColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(blackColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(blackColor) +
      ", 0.12)",
  },
  navItem: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: grayColor[0],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
    border: "none",
    borderRadius: "3px",
    position: "relative",
    padding: "12px 30px",
    margin: "1px 3px",
    fontSize: "12px",
    textTransform: "uppercase",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:hover,&:focus": {
      color: whiteColor,
    },
  },
  propertyStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "center",
    marginBottom: 5,
    padding: "6px 0px 0px",
  },
  propertyIcon: {
    width: 24,
    height: 24,
    marginLeft: 5,
    marginRight: 5,
  },
  outOfStock: {
    fontSize: "10px",
    lineHeight: "10px",
    fontWeight: "bold",
    background: "red",
    color: "white",
    textTransform: "uppercase",
    padding: "2px",
  },
});

export default CategoryStyle;
