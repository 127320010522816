import { container, main, mainRaised } from "assets/jss/material-kit-pro-react";

const PromotionStyle = {
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  mainRaised,
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  container: {
    ...container,
    zIndex: 1,
    paddingTop: 70,
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "100vh",
    position: "relative",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  promoImage: {
    width: "100%",
  },
};

export default PromotionStyle;
