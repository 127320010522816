import { container, main, mainRaised } from "assets/jss/material-kit-pro-react";
import badgeStyle from "assets/jss/material-kit-pro-react/components/badgeStyle.js";
import tooltipsStyle from "../../../assets/jss/material-kit-pro-react/tooltipsStyle";

const ProductStyle = {
  ...badgeStyle,
  ...tooltipsStyle,
  main: {
    ...main,
    backgroundColor: "transparent",
    paddingLeft: 20,
    paddingRight: 20,
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised: {
    ...mainRaised,
    marginTop: "-10px !important",
    marginBottom: 50,
    paddingBottom: 20,
  },
  container: {
    ...container,
    zIndex: 1,
    // minHeight: "100vh",
    paddingTop: 20,
    width: "90%",
  },
  chefRecommends: {
    maxWidth: 200,
    maxHeight: 200,
    margin: "auto",
    "& > img": {
      width: "100%",
    },
    marginBottom: 20,
  },
  productTitle: {
    textAlign: "center",
    lineHeight: "normal",
    marginBottom: 10,
  },
  productPrice: {
    textAlign: "center",
    lineHeight: "normal",
    marginBottom: 10,
  },
  productDescription: {
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },
  variations: {
    marginTop: 20,
    marginBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  carousel: {
    marginTop: 70,
    width: "100%",
  },
  productImage: {
    width: "100%",
  },
  videoWrapper: {
    position: "relative",
    paddingTop: "66.666667%",
    backgroundSize: "cover",
    backgroundPosition: "50%",
  },
  footer: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 0,
    paddingBottom: 40,
  },
  selectShrink: {
    "&.MuiInputLabel-shrink": {
      transform: "translate(0, -5px) scale(0.75)",
    },
  },
  propertyStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "center",
    marginBottom: 20,
  },
  propertyIcon: {
    width: 32,
    height: 32,
    marginLeft: 5,
    marginRight: 5,
  },
};

export default ProductStyle;
