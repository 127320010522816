import React from "react";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import FormInput from "./FormInput";
import FormSelect from "./FormSelect";
import FormRadio from "./FormRadio";
import FormCheckbox from "./FormCheckbox";
import FormStarRating from "./FormStarRating";
import { fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";
import DOMPurify from "dompurify";

function htmlDecode(input) {
  const doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default function FormItem(props) {
  const { item } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { theme_data, menu_country } = menuInfo.menuData;

  const headerStyle = {
    color: theme_data?.feedback_header_text_color,
    ...fontName(theme_data?.feedback_header_font_family),
  };

  const paragraphStyle = {
    color: theme_data?.feedback_paragraph_text_color,
    fontSize: Number(theme_data?.feedback_paragraph_font_size),
    ...fontName(theme_data?.feedback_paragraph_font_family),
  };

  const itemLabel = item?.label ? htmlDecode(item.label) : "";
  const paragraphContent = item?.label ? DOMPurify.sanitize(item.label) : "";
  const itemPlaceholder = item?.placeholder ? htmlDecode(item.placeholder) : "";
  const itemDescription = item?.description ? htmlDecode(item.description) : "";

  switch (item.type) {
    case "header":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <item.subtype style={headerStyle}>{itemLabel}</item.subtype>
          </GridItem>
        </GridContainer>
      );
    case "paragraph":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <div
              style={paragraphStyle}
              dangerouslySetInnerHTML={{ __html: paragraphContent }}
            />
          </GridItem>
        </GridContainer>
      );
    case "text":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormInput
              name={item.name}
              label={itemLabel}
              required={item.required}
              placeholder={itemPlaceholder}
              helperText={itemDescription}
              maxLength={item.maxlength}
              type={item.subtype}
              country={menu_country}
            />
          </GridItem>
        </GridContainer>
      );
    case "textarea":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormInput
              name={item.name}
              label={itemLabel}
              multiline
              rows={item.rows}
              required={item.required}
              placeholder={itemPlaceholder}
              helperText={itemDescription}
              maxLength={item.maxlength}
            />
          </GridItem>
        </GridContainer>
      );
    case "number":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormInput
              name={item.name}
              label={itemLabel}
              required={item.required}
              placeholder={itemPlaceholder}
              helperText={itemDescription}
              type={"number"}
              InputProps={{
                inputProps: {
                  max: item.max,
                  min: item.min,
                  step: item.step,
                },
              }}
            />
          </GridItem>
        </GridContainer>
      );
    case "select":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormSelect
              name={item.name}
              label={itemLabel}
              required={item.required}
              placeholder={itemPlaceholder}
              description={itemDescription}
              options={item.values}
              multiple={item.multiple}
            />
          </GridItem>
        </GridContainer>
      );
    case "radio-group":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormRadio
              name={item.name}
              label={itemLabel}
              required={item.required}
              description={itemDescription}
              options={item.values}
              inline={item.inline}
              other={item.other}
            />
          </GridItem>
        </GridContainer>
      );
    case "checkbox-group":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormCheckbox
              name={item.name}
              label={itemLabel}
              required={item.required}
              description={itemDescription}
              options={item.values}
              inline={item.inline}
              other={item.other}
              toggle={item.toggle}
            />
          </GridItem>
        </GridContainer>
      );
    case "date":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormInput
              name={item.name}
              label={itemLabel}
              required={item.required}
              placeholder={itemPlaceholder}
              helperText={itemDescription}
              type={item.subtype || "date"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </GridItem>
        </GridContainer>
      );
    case "starRating":
      return (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormStarRating
              name={item.name}
              label={itemLabel}
              required={item.required}
              numStars={item.placeholder}
              helperText={itemDescription}
            />
          </GridItem>
        </GridContainer>
      );
    default:
      return <div />;
  }
}
