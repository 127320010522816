import { card, container, main } from "assets/jss/material-kit-pro-react";

const DeliveryStyle = {
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  card: {
    ...card,
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: 10,
    justifyContent: "space-between",
    paddingLeft: 0,
    paddingRight: 0,
  },
  mapCard: {
    ...card,
    width: "85%",
    position: "absolute",
    bottom: "10vh",
    display: "flex",
    flexDirection: "column",
    padding: 30,
    background: "rgb(255 255 255 / 90%)",
  },
  mapCardSearchWrapper: {
    ...card,
    width: "85%",
    position: "absolute",
    top: 60,
    display: "flex",
    flexDirection: "column",
    padding: "0 10px",
    margin: 0,
    alignItems: "center",
  },
  mapCardSearchContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    width: "100%",
  },
  mapCardSearchAutoComplete: {
    width: `100%`,
  },
  mapCardSearchHistoryWrapper: {
    padding: 10,
    maxHeight: "40vh",
    overflowY: "auto",
  },
  addressContainer: {
    ...card,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    margin: "10px 0",
    padding: 10,
    borderBottom: "1px solid #e0e0e0",
  },
  addressLink: {
    fontSize: 12,
    color: "#4a4a4a",
    "&:hover, &:focus": {
      color: "#282828 !important",
      textDecoration: "none",
    },
  },
  selectedAddressContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
  },
  selectedAddress: {
    fontSize: 12,
    borderBottom: "1px solid #e3e3e3",
    marginLeft: 10,
    width: "100%",
  },
  formControl: {
    marginBottom: 0,
  },
};

export default DeliveryStyle;
