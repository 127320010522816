import React, { forwardRef, useEffect, useState } from "react";
// nodejs library that concatenates classes
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(CategoryStyle);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function QuantityModal(props) {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { ui_strings, theme_data } = menuInfo.menuData;

  const { qty, setQty, handleClose, handleOpen, maxQty } = props;

  const [quantity, setQuantity] = useState(qty);

  useEffect(() => {
    if (qty) {
      setQuantity(qty);
    }
  }, [qty]);

  const handleQtyChange = (event) => {
    if (event.target.value >= 1 && event.target.value <= maxQty) {
      setQuantity(event.target.value);
    }
  };

  const handleQtySave = () => {
    if (quantity >= 1 && quantity <= maxQty) {
      setQty(Number(quantity));
      handleClose();
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={handleOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="quantity-modal-title"
      aria-describedby="quantity-modal-description"
    >
      <DialogTitle
        id="quantity-modal-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={() => handleClose()}
        >
          {" "}
          <Close className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>
          {ui_strings?.ui_str_input_qty
            ? ui_strings.ui_str_input_qty
            : "Input Quantity"}
        </h4>
      </DialogTitle>
      <DialogContent
        id="quantity-modal-description"
        className={classes.modalBody}
      >
        <div>
          <TextField
            required
            id="qty"
            label="Quantity"
            type="number"
            value={quantity}
            onChange={handleQtyChange}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <Button
          style={{
            backgroundColor:
              theme_data?.category_screen_product_add_to_card_icon_color,
          }}
          onClick={() => handleQtySave()}
        >
          {ui_strings?.ui_str_save ? ui_strings.ui_str_save : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
