import reducers from "./reducers";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";

const middleware = [thunk];

function configureStore(initialState) {
  const enhancer = composeWithDevTools(applyMiddleware(...middleware));
  return createStore(reducers, initialState, enhancer);
}

const initialState = {};

export const store = configureStore(initialState);
export const persistor = persistStore(store);
