import React from "react";
import Button from "../../CustomButtons/Button";
import { fontName, isEmpty } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(CartStyle);

export default function CartCheckoutButton(props) {
  const classes = useStyles();
  const history = useHistory();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    ui_strings,
    theme_data,
    enable_address_google_maps,
  } = menuInfo.menuData;
  const {
    deliveryLocation,
    orderType,
    paymentType,
    deliveryLocationConfirmed,
    deliveryFormattedAddress,
    enableWishlist,
    minOrderAmountError,
    loadingCoupon,
    loadingEmployeeDiscount,
    loadingIikoLoyaltyDiscounts,
  } = cartInfo;

  const buttonStyle = {
    backgroundColor: theme_data?.cart_screen_submit_order_btn_color,
    color: theme_data?.cart_screen_submit_order_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_submit_order_btn_font_size),
    ...fontName(theme_data?.cart_screen_submit_order_btn_font_family),
  };

  const onClickHandler = () => {
    history.push("/checkout");
  };

  return (
    !enableWishlist &&
    !minOrderAmountError &&
    paymentType &&
    orderType &&
    ((enable_address_google_maps &&
      !isEmpty(deliveryLocation) &&
      deliveryLocationConfirmed &&
      deliveryFormattedAddress &&
      orderType === "delivery") ||
      (!enable_address_google_maps && orderType === "delivery") ||
      orderType !== "delivery") && (
      <div className={classes.checkoutButtonContainer}>
        <Button
          fullWidth
          className={classes.menuButton}
          style={buttonStyle}
          onClick={onClickHandler}
          disabled={
            !(
              (enable_address_google_maps &&
                !isEmpty(deliveryLocation) &&
                deliveryLocationConfirmed &&
                deliveryFormattedAddress &&
                orderType === "delivery") ||
              (!enable_address_google_maps && orderType === "delivery") ||
              orderType !== "delivery"
            ) ||
            loadingCoupon ||
            loadingEmployeeDiscount ||
            loadingIikoLoyaltyDiscounts
          }
        >
          {ui_strings?.ui_str_checkout
            ? ui_strings.ui_str_checkout
            : "Checkout"}
        </Button>
      </div>
    )
  );
}
