import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { Card, Radio, TableContainer } from "@material-ui/core";
import Button from "../../CustomButtons/Button";
import { useSelector } from "react-redux";
import axios from "axios";
import { APP_URL } from "../../../constants/Api";
import QuantityButton from "../Quantity/QuantityButton";
import CustomInput from "../../CustomInput/CustomInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import CustomTableCell from "../../Table/CustomTableCell";
import Pusher from "pusher-js";

const useStyles = makeStyles(CartStyle);

const splitAmount = (balanceDue, price_decimal_places) => {
  // Convert the string to a number and divide by 2
  let halfAmount = parseFloat(balanceDue) / 2;

  // Round to two decimal places
  halfAmount = halfAmount.toFixed(price_decimal_places);

  // Convert back to number to avoid string concatenation in sum
  halfAmount = parseFloat(halfAmount);

  // Create an array with two halves
  let splitAmounts = [halfAmount, halfAmount];

  // Adjust for rounding errors
  let sum = splitAmounts[0] + splitAmounts[1];
  if (sum !== parseFloat(balanceDue)) {
    // Adjust the second amount to account for rounding difference
    splitAmounts[1] += parseFloat(balanceDue) - sum;
    splitAmounts[1] = parseFloat(splitAmounts[1].toFixed(price_decimal_places));
  }

  return splitAmounts;
};

export default function AlFuttaimPaymentOptions({
  orderData,
  fetchOrderData,
  balanceDue,
}) {
  const orangeColor = "#FFA500";
  const greenColor = "#5CBF38";
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { currency, price_decimal_places } = menuInfo.menuData;
  const { split_payments } = orderData;
  const [loading, setLoading] = useState(false);
  const [openSplitPayment, setOpenSplitPayment] = useState(
    split_payments?.length > 0
  );
  const [totalSplitAmount, setTotalSplitAmount] = useState(2);
  const [splitValues, setSplitValues] = useState(
    splitAmount(balanceDue, price_decimal_places)
  );
  const [isTotalAmountValid, setIsTotalAmountValid] = useState(false);

  const [selectedSplitPayment, setSelectedSplitPayment] = useState(null);

  useEffect(() => {
    // Initialize Pusher
    const pusher = new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
      cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
      encrypted: true,
    });

    // Subscribe to the 'order_channel'
    const channel = pusher.subscribe(`${orderData?.uuid}`);

    // Bind to the 'order_update' event within the channel
    channel.bind("order-update", (data) => {
      console.log("Order updated", data);
      fetchOrderData();
    });

    // Unbind from the event and unsubscribe from the channel when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.uuid]);

  useEffect(() => {
    if (split_payments?.length > 0) {
      setOpenSplitPayment(true);
      const availableSplitPayment = split_payments.filter(
        (payment) => payment.status === "AVAILABLE"
      );

      if (availableSplitPayment.length > 0) {
        setSelectedSplitPayment(availableSplitPayment[0].uuid);
      } else {
        setSelectedSplitPayment(null);
      }
    } else {
      setSelectedSplitPayment(null);
    }
  }, [split_payments]);

  useEffect(() => {
    if (balanceDue) {
      setSplitValues(splitAmount(balanceDue, price_decimal_places));
    }
  }, [balanceDue, price_decimal_places]);

  useEffect(() => {
    while (splitValues.length < totalSplitAmount) {
      splitValues.push(0.0); // Add empty string for new inputs
    }
    while (splitValues.length > totalSplitAmount) {
      splitValues.pop(); // Remove values when decreasing quantity
    }

    // Check if total split amount is equal to balance due
    const totalAmount = splitValues
      .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      .toFixed(price_decimal_places);

    setIsTotalAmountValid(
      totalAmount === parseFloat(balanceDue).toFixed(price_decimal_places)
    );
  }, [balanceDue, price_decimal_places, splitValues, totalSplitAmount]);

  const handleSplitAmountChange = (index, value) => {
    const newValues = [...splitValues];
    newValues.splice(index, 1, Number(value));
    setSplitValues(newValues);
  };

  const handleSplitPaymentSettings = () => {
    setOpenSplitPayment(true);
  };

  const handleFullPayment = () => {
    setLoading(true);
    // Get the payment link for total amount of the order
    const order_id = orderData?.uuid;
    const payment_options = "full";

    axios
      .post(
        `${APP_URL}/iiko/af/payments/`,
        {
          order_id,
          payment_options,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200 && response.data.payment_url) {
          window.open(response.data.payment_url, "_self");
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleSplitPayment = () => {
    setLoading(true);
    // Get the payment link for total amount of the order
    const order_id = orderData?.uuid;
    const split_payment_uuid = selectedSplitPayment;

    axios
      .post(
        `${APP_URL}/iiko/af/split-payments/`,
        {
          order_id,
          split_payment_uuid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        if (response.status === 200 && response.data.payment_url) {
          window.open(response.data.payment_url, "_self");
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const handleConfirmSplit = () => {
    setLoading(true);
    // Get the payment link for total amount of the order
    if (isTotalAmountValid) {
      const order_id = orderData?.uuid;
      const split_amounts = splitValues;

      axios
        .post(
          `${APP_URL}/iiko/af/split-payments/create/`,
          {
            order_id,
            split_amounts,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            fetchOrderData();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Card
      className={classes.card}
      style={{
        marginBottom: "100px",
      }}
    >
      <div className={classes.cardContainer}>
        <div className={classes.cardTitle}>Payment Options</div>
        <div>
          <div className={classes.cardContent}>
            <div className={classes.paymentOptionsContent}>
              {!openSplitPayment ? (
                <>
                  <Button
                    className={classes.paymentButton}
                    onClick={handleFullPayment}
                    disabled={loading}
                  >
                    {loading ? "Redirecting ..." : "Pay Full Amount"}
                  </Button>
                  <Button
                    className={classes.paymentButton}
                    onClick={handleSplitPaymentSettings}
                    disabled={loading}
                  >
                    Split Payment
                  </Button>
                </>
              ) : (
                <div className={classes.splitPaymentContent}>
                  {split_payments?.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        marginBottom: "20px",
                        fontSize: "12px",
                      }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="split payments">
                          <TableHead>
                            <TableRow>
                              <CustomTableCell align={"center"}>
                                Select
                              </CustomTableCell>
                              <CustomTableCell>Split</CustomTableCell>
                              <CustomTableCell align="right">
                                Amount
                              </CustomTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {split_payments.map((payment, index) => (
                              <TableRow key={index}>
                                <CustomTableCell align={"center"}>
                                  {payment.status === "AVAILABLE" ? (
                                    <Radio
                                      checked={
                                        selectedSplitPayment === payment.uuid
                                      }
                                      onChange={(e) =>
                                        setSelectedSplitPayment(payment.uuid)
                                      }
                                      value={payment.uuid}
                                      name={payment.guest_name}
                                      inputProps={{
                                        "aria-label": `${payment.guest_name}`,
                                      }}
                                    />
                                  ) : payment.status === "PENDING" ? (
                                    "In Progress"
                                  ) : (
                                    "Paid"
                                  )}
                                </CustomTableCell>
                                <CustomTableCell>
                                  {payment.guest_name}
                                </CustomTableCell>
                                <CustomTableCell align={"right"}>
                                  {currency}{" "}
                                  {Number(payment.amount).toFixed(
                                    price_decimal_places
                                  )}
                                </CustomTableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Button
                        fullWidth
                        className={classes.paymentButton}
                        onClick={handleSplitPayment}
                        disabled={loading || !selectedSplitPayment}
                        size={"lg"}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        {!loading
                          ? `Pay Split Amount - ${
                              selectedSplitPayment
                                ? `${currency} ${Number(
                                    split_payments?.find(
                                      (payment) =>
                                        payment.uuid === selectedSplitPayment
                                    )?.amount
                                  ).toFixed(price_decimal_places)}`
                                : "--"
                            }`
                          : `Redirecting ...`}
                      </Button>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <div style={{ fontSize: "10px" }}>Number of Guests</div>
                        <QuantityButton
                          cart={false}
                          minQty={2}
                          maxQty={6}
                          qty={totalSplitAmount}
                          setQty={setTotalSplitAmount}
                          title={" "}
                        />
                        <div style={{ fontSize: "10px" }}>
                          Max upto 6 guests
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          marginBottom: "20px",
                          fontSize: "12px",
                        }}
                      >
                        <TableContainer component={Paper}>
                          <Table aria-label="split payments">
                            <TableHead>
                              <TableRow>
                                <CustomTableCell align={"center"}>
                                  No.
                                </CustomTableCell>
                                <CustomTableCell>Split</CustomTableCell>
                                <CustomTableCell align="right">
                                  Amount
                                </CustomTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {[...Array(totalSplitAmount)].map((_, index) => (
                                <TableRow key={index}>
                                  <CustomTableCell align={"center"}>{`${
                                    index + 1
                                  }`}</CustomTableCell>
                                  <CustomTableCell>{`Guest # ${index + 1} ${
                                    index === 0 ? "(You)" : ""
                                  }`}</CustomTableCell>
                                  <CustomTableCell align={"right"}>
                                    <CustomInput
                                      formControlProps={{
                                        className: classes.splitInput,
                                        style: {
                                          marginBottom: 0,
                                        },
                                      }}
                                      inputProps={{
                                        inputProps: {
                                          style: {
                                            fontSize: "12px",
                                            maxWidth: "40px",
                                          },
                                          type: "number",
                                          min: 0,
                                          value: splitValues[index] || null,
                                          onChange: (e) =>
                                            handleSplitAmountChange(
                                              index,
                                              e.target.value
                                            ),
                                        },
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <div style={{ fontSize: "12px" }}>
                                              {currency}
                                            </div>
                                          </InputAdornment>
                                        ),
                                      }}
                                      id={`split-amount-${index}`}
                                    />
                                  </CustomTableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <CustomTableCell
                                  colSpan={2}
                                  align="right"
                                  style={{
                                    fontWeight: "bold",
                                    color: isTotalAmountValid
                                      ? greenColor
                                      : orangeColor,
                                  }}
                                >
                                  Split Total
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  style={{
                                    fontWeight: "bold",
                                    color: isTotalAmountValid
                                      ? greenColor
                                      : orangeColor,
                                  }}
                                >
                                  {`${currency} `}
                                  {splitValues
                                    .reduce(
                                      (a, b) => parseFloat(a) + parseFloat(b),
                                      0
                                    )
                                    .toFixed(price_decimal_places)}
                                </CustomTableCell>
                              </TableRow>
                              <TableRow>
                                <CustomTableCell
                                  colSpan={2}
                                  align="right"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Balance Due
                                </CustomTableCell>
                                <CustomTableCell
                                  align="right"
                                  style={{ fontWeight: "bold" }}
                                >
                                  {`${currency} `}
                                  {parseFloat(balanceDue).toFixed(
                                    price_decimal_places
                                  )}
                                </CustomTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                      <Button
                        fullWidth
                        className={classes.paymentButton}
                        onClick={handleConfirmSplit}
                        disabled={loading || !isTotalAmountValid}
                        size={"lg"}
                      >
                        {isTotalAmountValid
                          ? "Confirm Split"
                          : "Invalid Split Total"}
                      </Button>
                      <Button
                        onClick={() => setOpenSplitPayment(false)}
                        disabled={loading}
                        color={"danger"}
                      >
                        Cancel Split Payment
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
