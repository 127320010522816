import {
  blackColor,
  card,
  container,
  grayColor,
  hexToRgb,
  main,
  roseColor,
  whiteColor,
} from "assets/jss/material-kit-pro-react";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.js";

const CartStyle = (theme) => ({
  ...modalStyle(theme),
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 70,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  card: {
    ...card,
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: 10,
    justifyContent: "space-between",
  },
  cardContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: "bold",
    borderBottom: "1px solid #e0e0e0",
    padding: "2px 0 8px",
  },
  cardContent: {
    padding: "10px",
  },
  tipsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  tipsButtonGrouped: {
    flex: 1,
    color: "#000 !important",
  },
  tipsContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  tipsAmount: {
    fontSize: 16,
    fontWeight: "bold",
  },
  blueRewardsContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    width: "100%",
  },
  blueRewardsCustomerDetails: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    fontSize: "12px",
  },
  blueRewardsCustomerName: {
    flex: 1,
  },
  blueRewardsCustomerMobile: {
    // flex: 1,
  },
  blueRewardsWalletBalance: {
    backgroundColor: "#0EB0ED",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    filter: "drop-shadow(0px 6px 4px rgba(" + hexToRgb(blackColor) + ", 0.04))",
    webkitFilter:
      "drop-shadow(0px 6px 4px rgba(" + hexToRgb(blackColor) + ", 0.04))",
    padding: "10px 0",
    borderRadius: "4px",
    marginTop: "10px",
    fontSize: "12px",
  },
  blueRewardsWalletBalanceAmount: {
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: "8px",
  },
  blueRewardsWalletRedeemContent: {
    alignItems: "center",
    fontSize: "10px",
  },
  paymentOptionsContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  paymentButton: {
    backgroundColor: "#000000",
    color: "white !important",
    fontWeight: "bold",
  },
  splitPaymentContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  splitInput: {
    paddingTop: 0,
  },
  menuButtonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 30,
  },
  menuButton: {
    marginTop: 30,
    marginBottom: 5,
  },
  itemDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    maxWidth: "75%",
  },
  itemImage: {
    maxWidth: "64px",
  },
  itemDetails: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  itemQty: {
    display: "flex",
    flexDirection: "column",
    width: "25%",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  itemDeleteBtn: {
    padding: 0,
  },
  itemSpecialInstructions: {
    fontStyle: "italic",
    fontSize: 12,
  },
  itemVariation: {
    borderTop: "1px solid #e0e0e0",
    "&:last-child": {
      borderBottom: "1px solid #e0e0e0",
      marginBottom: 10,
    },
  },
  itemVariationName: {
    fontSize: 12,
  },
  itemVariationList: {
    marginBottom: 5,
  },
  itemVariationValue: {
    fontSize: 12,
    marginLeft: 20,
    marginRight: 20,
    lineHeight: "1.2em",
  },
  menuTotalsContainer: {
    width: "100%",
    position: "relative",
  },
  menuTotals: {
    ...card,
    margin: "30px 0 0",
    display: "flex",
    flexDirection: "column",
    padding: 10,
    paddingBottom: 20,
    justifyContent: "space-between",
    zIndex: -1,
  },
  menuTotalsItem: {
    borderBottom: "1px solid #e0e0e0",
    marginBottom: 5,
  },
  menuTotalsWave: {
    marginTop: -5,
    marginBottom: 30,
    zIndex: 1,
    filter: "drop-shadow(0px 6px 4px rgba(" + hexToRgb(blackColor) + ", 0.04))",
    webkitFilter:
      "drop-shadow(0px 6px 4px rgba(" + hexToRgb(blackColor) + ", 0.04))",
  },
  couponCard: {
    ...card,
    margin: "10px 0 -28px",
    padding: 10,
  },
  couponContainer: {
    width: "100%",
  },
  couponGridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    alignContent: "center",
  },
  couponCode: {
    fontWeight: "bold",
    border: "1px dashed",
    borderColor: roseColor[0],
    padding: "6px 20px",
    fontSize: 25,
    color: roseColor[0],
    margin: "20px 0",
  },
  checkoutButtonContainer: {
    width: "100%",
    marginBottom: "50px",
  },
  emptySpace: {
    margin: 50,
  },
  selectedAddressContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #35954D",
    width: "80%",
  },
  selectedAddress: {
    fontSize: 12,
    marginLeft: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  selectedAddressHint: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#35954D",
  },
  paymentTypeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  paymentTypeTitle: {
    marginBottom: 10,
    fontSize: 12,
  },
  paymentTypeButtonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    backgroundColor: "transparent",
  },
  paymentTypeButtonSelected: {
    color: "#fff !important",
    backgroundColor: "#35954D !important",
  },
  orderCommentContainer: {
    width: "80%",
  },
  orderCommentLabel: {
    color: `${grayColor[1]} !important`,
  },
  orderTypeContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 0 20px",
    width: "100%",
    backgroundColor: "transparent",
  },
  toggleButton: {
    minHeight: "auto",
    minWidth: "auto",
    backgroundColor: grayColor[0],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[0]) +
      ", 0.12)",
    border: "none",
    borderRadius: "3px !important",
    position: "relative",
    margin: ".3125rem 10px",
    fontSize: "13px",
    fontWeight: "700",
    letterSpacing: "0",
    willChange: "box-shadow, transform",
    transition:
      "box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)",
    textAlign: "center",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    touchAction: "manipulation",
    cursor: "pointer",
    "&:first-child": {
      borderRadius: "3px",
    },
    "&:last-child": {
      paddingLeft: "10px",
      borderRadius: "3px",
    },
  },
  upsellProductsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  upsellProductsTitle: {
    fontSize: 14,
    fontWeight: "bold",
    padding: "2px 0 8px",
  },
  upsellProductsWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  upsellProductCard: {
    ...card,
    marginRight: "10px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    padding: "0",
    justifyContent: "space-between",
    minWidth: "33%",
    maxWidth: "33%",
  },
  upsellProductTitle: {
    padding: "0.5rem",
    textAlign: "center",
  },
  upsellProductChefRecommends: {
    width: 20,
    height: 20,
    margin: "auto",
    marginTop: -20,
    position: "absolute",
    zIndex: 2,
    left: "45%",
    "& > img": {
      width: "100%",
    },
  },
  upsellProductOutOfStock: {
    fontSize: "10px",
    lineHeight: "10px",
    fontWeight: "bold",
    background: "red",
    color: "white",
    textTransform: "uppercase",
    padding: "2px",
  },
  upsellProductPropertyStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
    justifyContent: "center",
    marginBottom: 5,
    padding: "6px 0px 0px",
  },
});

export default CartStyle;
