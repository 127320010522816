import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { Card, SvgIcon } from "@material-ui/core";
import { ReactComponent as BlueRewardsIcon } from "../../../assets/svg/blue-rewards.svg";
import { useSelector } from "react-redux";
import CustomInput from "../../CustomInput/CustomInput";
import Button from "../../CustomButtons/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import axios from "axios";
import { APP_URL } from "../../../constants/Api";

const useStyles = makeStyles(CartStyle);

export default function AlFuttaimBlueRewards(props) {
  const classes = useStyles();
  const cartInfo = useSelector((state) => state.cartInfo);
  const menuInfo = useSelector((state) => state.menuInfo);
  const { currency, price_decimal_places } = menuInfo.menuData;
  const { blueWalletData, loadingBlueWalletData, tipsAmount } = cartInfo;
  const { orderData, fetchOrderData } = props;
  const { additional_data, payments, split_payments } = orderData;
  const [redeemAmount, setRedeemAmount] = useState(
    Number(orderData?.balance_due ?? 0)
  );
  const [maxRedeemAmount, setMaxRedeemAmount] = useState(
    Number(orderData?.balance_due ?? 0)
  );
  const [redeemOTP, setRedeemOTP] = useState("");
  const [redeemError, setRedeemError] = useState(false);
  const [redeemingBlueWallet, setRedeemingBlueWallet] = useState(false);

  const [showBluePayments, setShowBluePayments] = useState(true);

  useEffect(() => {
    if (split_payments?.length > 0) {
      setShowBluePayments(false);
    } else if (payments && payments.length > 0) {
      setShowBluePayments(
        !payments?.find((payment) => payment.payment_type === "blue_wallet")
      );
    } else {
      setShowBluePayments(true);
    }
  }, [payments, split_payments]);

  useEffect(() => {
    // Check if blue wallet balance is less than order balance due
    // If yes, set redeem amount to blue wallet balance
    // If no, check if order total is greater than order balance due, (tips added)
    // then set the redeem amount to total
    // else set redeem amount to order balance due
    if (blueWalletData?.balance && blueWalletData?.balance > 0) {
      if (blueWalletData?.balance < orderData?.balance_due) {
        setRedeemAmount(blueWalletData?.balance ?? 0);
        setMaxRedeemAmount(blueWalletData?.balance ?? 0);
      } else {
        if (tipsAmount !== "0.00" && !additional_data?.tips_amount) {
          setRedeemAmount(
            Number(
              Number(orderData?.balance_due ?? 0) + Number(tipsAmount)
            ).toFixed(price_decimal_places)
          );
          setMaxRedeemAmount(
            Number(
              Number(orderData?.balance_due ?? 0) + Number(tipsAmount)
            ).toFixed(price_decimal_places)
          );
        } else {
          setRedeemAmount(Number(orderData?.balance_due ?? 0));
          setMaxRedeemAmount(Number(orderData?.balance_due ?? 0));
        }
      }
    }
  }, [
    price_decimal_places,
    orderData?.balance_due,
    tipsAmount,
    blueWalletData?.balance,
    additional_data?.tips_amount,
  ]);

  const blueRedeem = () => {
    const orderUUId = orderData?.uuid;
    setRedeemError(false);
    setRedeemingBlueWallet(true);

    axios
      .post(
        `${APP_URL}/iiko/af/blue/${orderUUId}/`,
        {
          wallet_id: blueWalletData?.wallet_id,
          amount: redeemAmount,
          otp: redeemOTP,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          fetchOrderData();
        } else {
          setRedeemError(true);
        }
      })
      .catch((error) => {
        setRedeemError(true);
        console.error(error);
      })
      .finally(() => {
        setRedeemOTP("");
        setRedeemingBlueWallet(false);
      });
  };

  return showBluePayments ? (
    <Card className={classes.card}>
      <div className={classes.cardContainer}>
        <div className={classes.cardTitle}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <SvgIcon
              component={BlueRewardsIcon}
              viewBox="0 0 40 40"
              style={{
                fontSize: "36px",
              }}
            />
            <div style={{ marginLeft: "10px" }}>Pay using Blue Wallet</div>
          </div>
        </div>
        <div>
          <div className={classes.cardContent}>
            <div
              className={classes.blueRewardsContent}
              style={{
                filter: loadingBlueWalletData ? "blur(3px)" : "none",
              }}
            >
              <div className={classes.blueRewardsCustomerDetails}>
                <div className={classes.blueRewardsCustomerName}>
                  Name: {blueWalletData?.name ? blueWalletData?.name : "--"}
                </div>
                <div className={classes.blueRewardsCustomerMobile}>
                  Mobile: {orderData?.phone ? orderData?.phone : "--"}
                </div>
              </div>

              <div className={classes.blueRewardsWalletBalance}>
                Wallet Balance:{" "}
                <span className={classes.blueRewardsWalletBalanceAmount}>
                  {`${currency} `}
                  {blueWalletData?.balance && blueWalletData?.balance > 0
                    ? blueWalletData?.balance
                    : "0.00"}
                </span>
              </div>

              {blueWalletData?.balance &&
              blueWalletData?.balance > 0 &&
              orderData?.balance_due > 0 ? (
                <GridContainer
                  className={classes.blueRewardsWalletRedeemContent}
                >
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      labelText={"Amount"}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        inputProps: {
                          type: "number",
                          min: 0,
                          max: maxRedeemAmount,
                          value: redeemAmount,
                          onChange: (e) => {
                            setRedeemAmount(e.target.value);
                          },
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                      formHelperText={"Amount to Redeem"}
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <CustomInput
                      labelText={"Enter OTP"}
                      inputProps={{
                        inputProps: {
                          type: "number",
                          value: redeemOTP,
                          placeholder: "######",
                          maxLength: 6,
                          onChange: (e) => {
                            setRedeemOTP(e.target.value);
                          },
                        },
                      }}
                      error={redeemError}
                      formHelperText={
                        redeemError ? "Incorrect OTP" : "Get from Blue App"
                      }
                    />
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <Button
                      className={classes.paymentButton}
                      disabled={redeemingBlueWallet || redeemOTP.length < 6}
                      onClick={() => blueRedeem()}
                      size={"sm"}
                    >
                      {redeemingBlueWallet ? "Loading ..." : "REDEEM"}
                    </Button>
                  </GridItem>
                </GridContainer>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Card>
  ) : null;
}
