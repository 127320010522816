import React from "react";
import Button from "../../CustomButtons/Button";
import { fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(CartStyle);

export default function AlFuttaimCheckoutButton(props) {
  const classes = useStyles();
  const history = useHistory();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { theme_data } = menuInfo.menuData;
  const { afActiveOrder, afOrderAccepted } = cartInfo;

  const buttonStyle = {
    backgroundColor: theme_data?.cart_screen_submit_order_btn_color,
    color: theme_data?.cart_screen_submit_order_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_submit_order_btn_font_size),
    ...fontName(theme_data?.cart_screen_submit_order_btn_font_family),
  };

  const onClickHandler = () => {
    history.push(`/af-checkout/${afActiveOrder}`);
  };

  return (
    !!afActiveOrder &&
    afOrderAccepted && (
      <div className={classes.checkoutButtonContainer}>
        <Button
          fullWidth
          className={classes.menuButton}
          style={buttonStyle}
          onClick={onClickHandler}
        >
          {"CHECKOUT"}
        </Button>
      </div>
    )
  );
}
