import React from "react";
import { NavLink } from "react-router-dom";

export default function EmenuNavlink({
  to,
  disabled,
  external,
  children,
  target = "_self",
  className,
  ...rest
}) {
  const handleClick = () => {
    saveScrollPosition();
  };

  return disabled ? (
    <div className={className}>{children}</div>
  ) : external ? (
    <a rel="noreferrer" href={external} className={className} target={target}>
      {children}
    </a>
  ) : (
    <NavLink className={className} to={to} onClick={handleClick} {...rest}>
      {children}
    </NavLink>
  );
}

// Function to save scroll position
export const saveScrollPosition = () => {
  const categoryId = extractCategoryId(window.location.href);
  if (categoryId) {
    localStorage.setItem(
      "scrollPosition",
      JSON.stringify({
        y: window.scrollY,
        categoryId: categoryId,
      })
    );
  }
};

export const clearScrollPosition = () => {
  localStorage.removeItem("scrollPosition");
};

const extractCategoryId = (url) => {
  // Define a regular expression to match the category ID
  const categoryRegex = /\/category\/(\d+)/;

  // Execute the regex on the given URL
  const match = url.match(categoryRegex);

  // If a match is found, return the category ID; otherwise, return null
  if (match) {
    return match[1];
  } else {
    return null;
  }
};
