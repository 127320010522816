import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeliveryStyle from "../../../views/DeliveryPage/DeliveryStyle";
import { Card } from "@material-ui/core";
import { Autocomplete } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import HistoryIcon from "@material-ui/icons/History";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import IconButton from "@material-ui/core/IconButton";
import { css } from "@emotion/css";
import CustomInput from "../../CustomInput/CustomInput";
import { removeAddress, selectAddress } from "../../../store/actions/cart";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(DeliveryStyle);

export default function MapSearch(props) {
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { ui_strings, theme_data } = menuInfo?.menuData;
  const { deliveryLocationConfirmed, savedAddresses } = cartInfo;
  const { defaultPosition, setDefaultPosition } = props;
  const classes = useStyles();
  const [searchBox, setSearchBox] = useState({});
  const [showHistory, setShowHistory] = useState(false);

  const onPlaceChanged = () => {
    if (searchBox != null) {
      const place = searchBox.getPlace();
      if (place.geometry) {
        setDefaultPosition({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        });
        setShowHistory(false);
      }
    }
  };

  const center = new window.google.maps.LatLng(
    defaultPosition?.lat,
    defaultPosition?.lng
  );
  const circle = new window.google.maps.Circle({
    radius: 10000,
    center: center,
  });
  const bounds = circle.getBounds();

  const handleHistoryButton = () => {
    setShowHistory(!showHistory);
  };

  const selectAddressHandler = (event, address) => {
    event.preventDefault();
    setDefaultPosition(address.deliveryLocation);
    setShowHistory(false);
    dispatch(selectAddress(address));
  };

  return (
    !deliveryLocationConfirmed && (
      <Card className={classes.mapCardSearchWrapper}>
        {defaultPosition && (
          <div className={classes.mapCardSearchContainer}>
            <Autocomplete
              onPlaceChanged={onPlaceChanged}
              onLoad={(ref) => setSearchBox(ref)}
              className={classes.mapCardSearchAutoComplete}
              bounds={bounds || null}
            >
              <CustomInput
                id={"search"}
                labelText={
                  ui_strings?.ui_str_map_search
                    ? ui_strings.ui_str_map_search
                    : "Search"
                }
                formControlProps={{
                  fullWidth: true,
                  style: { margin: "0 0 5px 0" },
                }}
                inputProps={{
                  type: "text",
                  placeholder: ui_strings?.ui_str_map_search
                    ? ui_strings.ui_str_map_search
                    : "Search",
                  className: css`
                    &::after {
                      border-bottom: 2px solid
                        ${theme_data?.cart_screen_form_input_border_color};
                    }
                  `,
                }}
              />
            </Autocomplete>
            {savedAddresses.length > 0 && (
              <IconButton
                aria-label="History"
                onClick={handleHistoryButton}
                style={{
                  color: theme_data?.cart_screen_continue_shopping_btn_color,
                }}
              >
                <HistoryIcon />
              </IconButton>
            )}
          </div>
        )}
        <div
          className={classes.mapCardSearchHistoryWrapper}
          style={{ display: showHistory ? "block" : "none" }}
        >
          {savedAddresses.map((address, index) => (
            <div key={address.uuid} className={classes.addressContainer}>
              <Link
                href="#"
                onClick={(e) => selectAddressHandler(e, address)}
                color={"inherit"}
                className={classes.addressLink}
              >
                {address.deliveryFormattedAddress}
              </Link>
              <IconButton
                aria-label="Delete Address"
                onClick={() => dispatch(removeAddress(address.uuid))}
              >
                <HighlightOffIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </Card>
    )
  );
}
