import {
  container,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react";

const OnboardingStyle = (theme) => ({
  containerWrapper: {
    height: "100vh",
    width: "100%",
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "hidden",
    backgroundColor: "black",
  },
  container: {
    ...container,
    zIndex: 1,
  },
  loadingContainer: {
    ...container,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100%",
  },
  title: {
    ...title,
    color: whiteColor,
  },
  brand: {
    color: whiteColor,
    textAlign: "center",
    "& h1": {
      fontSize: "2.2rem",
      fontWeight: "600",
      display: "inline-block",
      position: "relative",
    },
  },
  onboardingContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    marginTop: "65vh",
  },
  menuButton: {
    textAlign: "center",
    width: "90%",
    "& button": {
      width: "100%",
    },
  },
  languageSelector: {
    padding: 10,
    display: "flex",
    alignItems: "center",
  },
  formContainer: {
    backgroundColor: whiteColor,
    marginTop: "-55vh",
    width: "90%",
    padding: 10,
    borderRadius: 10,
  },
  form: {
    padding: 10,
    "& .MuiGrid-container": {
      marginTop: 20,
    },
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: theme.spacing(1),
  },
  modal: {
    backgroundColor: "transparent",
  },
});

export default OnboardingStyle;
