import { makeStyles } from "@material-ui/core/styles";
import QuantityStyle from "./QuantityStyle";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { updateCartQty } from "../../../store/actions/cart";
import QuantityModal from "../Product/QuantityModal";
import { convertNumerals } from "../../../constants/utils";

const useStyles = makeStyles(QuantityStyle);

export default function QuantityButton(props) {
  const dispatch = useDispatch();
  const { uuid, qty, setQty, cart, minQty = 1, maxQty, title } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { selected_language, menuData } = menuInfo;
  const {
    ui_strings,
    theme_data,
    menu_country,
    enable_arabic_numerals,
  } = menuData;

  const [showQtyModal, setShowQtyModal] = useState(false);
  const maxQuantity = maxQty ? maxQty : Infinity;

  const handleQtyUpdate = (operation) => {
    // Update Qty
    let quantity = qty;
    if (operation === "add") {
      quantity = qty < maxQuantity ? qty + 1 : qty;
    } else {
      quantity = qty > minQty ? qty - 1 : qty;
    }
    setQty(quantity);
    if (uuid) {
      dispatch(updateCartQty(uuid, quantity));
    }
  };

  return (
    <div className={cart ? classes.cartContainer : classes.container}>
      {!cart && (
        <div className={classes.title}>
          {title
            ? title
            : ui_strings?.ui_str_qty
            ? ui_strings.ui_str_qty
            : "Qty"}
        </div>
      )}
      <div className={classes.buttons}>
        <Button
          className={cart ? classes.cartButton : classes.button}
          style={{
            borderColor: theme_data?.product_screen_add_to_cart_btn_color,
            color: theme_data?.product_screen_add_to_cart_btn_color,
          }}
          size={cart ? "small" : "medium"}
          variant={"outlined"}
          color={"primary"}
          onClick={() => handleQtyUpdate("minus")}
        >
          −
        </Button>
        <div
          className={cart ? classes.cartQty : classes.qty}
          onClick={() => !cart && setShowQtyModal(true)}
        >
          {convertNumerals(
            qty,
            selected_language,
            menu_country,
            enable_arabic_numerals,
            0
          )}
        </div>
        <Button
          className={cart ? classes.cartButton : classes.button}
          style={{
            borderColor: theme_data?.product_screen_add_to_cart_btn_color,
            color: theme_data?.product_screen_add_to_cart_btn_color,
          }}
          size={cart ? "small" : "medium"}
          variant={"outlined"}
          color={"primary"}
          onClick={() => handleQtyUpdate("add")}
        >
          +
        </Button>
      </div>
      {!cart && (
        <QuantityModal
          qty={qty}
          setQty={setQty}
          maxQty={maxQuantity}
          handleOpen={showQtyModal}
          handleClose={() => setShowQtyModal(false)}
        />
      )}
    </div>
  );
}
