import React from "react";
import { useSelector } from "react-redux";
import FlatList from "flatlist-react";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
import CartProduct from "./CartProduct";

const useStyles = makeStyles(CartStyle);

export default function CartProducts(props) {
  const cartInfo = useSelector((state) => state.cartInfo);
  const { products } = cartInfo;

  const classes = useStyles();

  const renderEmpty = () => {
    return <div className={classes.emptyList} />;
  };

  const renderProduct = (item) => {
    return <CartProduct key={item.uuid} item={item} />;
  };

  return (
    <FlatList
      list={products}
      renderItem={renderProduct}
      renderWhenEmpty={renderEmpty}
      keyExtractor={(item) => item.uuid}
    />
  );
}
