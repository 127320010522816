import * as types from "./types";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { APP_URL } from "../../constants/Api";
import { formatNumber } from "libphonenumber-js";

function calculateDiscount() {
  return async (dispatch, getState) => {
    const { cartInfo } = getState();
    const { products: cartProducts, coupon } = cartInfo;

    let discount = 0;

    if (coupon && cartProducts) {
      const {
        products,
        categories,
        discount_value_type,
        discount_value,
        min_order_value,
        max_discount_value,
      } = coupon;

      const cartTotal = cartProducts
        .reduce((prev, product) => prev + product.price * product.qty, 0)
        .toFixed(3);

      const min_total = parseFloat(min_order_value) || "0";

      if (min_total <= cartTotal) {
        let eligibleProducts = [];

        if (products.length > 0 || categories.length > 0) {
          eligibleProducts = cartProducts.filter(
            (product) =>
              products.includes(product.id) ||
              categories.includes(product.category)
          );
        } else {
          eligibleProducts = cartProducts;
        }

        if (eligibleProducts.length > 0) {
          const subtotal = eligibleProducts
            .reduce((prev, product) => prev + product.price * product.qty, 0)
            .toFixed(3);

          if (discount_value_type === "percentage") {
            discount = ((subtotal * parseFloat(discount_value)) / 100).toFixed(
              3
            );
          } else {
            if (parseFloat(discount_value) >= subtotal) {
              discount = subtotal;
            } else {
              discount = parseFloat(discount_value).toFixed(3);
            }
          }

          if (max_discount_value && parseFloat(max_discount_value) < discount) {
            discount = parseFloat(max_discount_value).toFixed(3);
          }
        }
      }
    }

    dispatch({
      type: types.CALCULATE_DISCOUNT,
      discount: discount,
    });
  };
}

export function resetIikoActiveOrders() {
  return async (dispatch) => {
    dispatch({
      type: types.RESET_AF_ACTIVE_ORDER,
    });
  };
}

export function fetchBlueWalletData(orderUUId) {
  return async (dispatch) => {
    dispatch({
      type: types.LOADING_BLUE_WALLET_DATA,
    });

    axios
      .get(`${APP_URL}/iiko/af/blue/${orderUUId}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.SAVE_BLUE_WALLET_DATA,
            blueWalletData: response.data,
          });
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          //console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //console.log("Error", error.message);
        }
        //console.log(error.config);
        dispatch({
          type: types.SAVE_BLUE_WALLET_DATA,
          blueWalletData: {},
        });
      });
  };
}

export function getIikoActiveOrders() {
  return async (dispatch, getState) => {
    const { menuInfo, cartInfo } = getState();
    const { menuData } = menuInfo;
    const { id: menuId } = menuData;
    const { orderLocation } = cartInfo;
    axios
      .get(`${APP_URL}/iiko/active-order/${orderLocation}/${menuId}/`)
      .then((response) => {
        if (response.status === 200) {
          // Save IIKO discounts and calculate discount
          if (!!response.data.order_id) {
            dispatch(afSetOrderSubmitted(response.data.order_id));
            if (response?.data?.common_order) {
              dispatch({
                type: types.AF_ORDER_COMMON,
              });
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function getIikoLoyaltyPromotions() {
  return async (dispatch, getState) => {
    const { menuInfo, cartInfo } = getState();
    const { menuData } = menuInfo;
    const { id: menuId, al_futtaim_enabled } = menuData;

    const { products: cartItems, employeeID, orderType } = cartInfo;

    // Send to iiko loyalty promotion endpoint and add to cart discounts
    if (al_futtaim_enabled) {
      dispatch({
        type: types.LOADING_IIKO_LOYALTY_DISCOUNTS,
      });

      axios
        .post(`${APP_URL}/iiko/loyalty/promotions/`, {
          emenu_id: menuId,
          cart_items: cartItems,
          employee_id: employeeID,
          order_type: orderType,
        })
        .then((response) => {
          if (response.status === 200) {
            // Save IIKO discounts and calculate discount
            dispatch({
              type: types.SAVE_IIKO_LOYALTY_DISCOUNTS,
              iikoLoyaltyDiscounts: response.data,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
}

export function addEmployeeDiscount(employee_id) {
  return async (dispatch) => {
    dispatch({
      type: types.LOADING_EMPLOYEE_DISCOUNT,
    });

    // Confirm if valid employee ID
    // If valid, check for promotions (to fetch Employee Discount + any other promos)
    axios
      .get(`${APP_URL}/iiko/af/verify-employee-id/${employee_id}/`)
      .then((res) => {
        dispatch({
          type: types.SAVE_EMPLOYEE_DISCOUNT,
          employeeID: res.data.employee_id,
          employeePhone: res.data.employee_phone,
          disableCustomerPhone: !!res.data.employee_phone,
        });
        dispatch(getIikoLoyaltyPromotions());
      })
      .catch((error) => {
        // Error
        if (error.response) {
          dispatch({
            type: types.INVALID_EMPLOYEE_DISCOUNT,
          });
        }
      });
  };
}

export const deleteEmployeeID = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DELETE_EMPLOYEE_DISCOUNT,
    });
    dispatch(getIikoLoyaltyPromotions());
  };
};

export const addTipsAmount = (tipsAmount) => {
  return async (dispatch, getState) => {
    const { cartInfo } = getState();
    const { afActiveOrder } = cartInfo;

    dispatch({
      type: types.ADDING_TIPS_AMOUNT,
    });

    axios
      .post(`${APP_URL}/iiko/tips/loyalty/promotions/`, {
        order_uuid: afActiveOrder,
        tips_amount: tipsAmount,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: types.ADD_TIPS_AMOUNT,
            tipsAmount,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch({
          type: types.SAVE_TIPS_AMOUNT,
        });
      });
  };
};

export function addCoupon(coupon) {
  return async (dispatch, getState) => {
    const { menuInfo } = getState();

    const menuId = menuInfo?.menuData?.id;

    dispatch({
      type: types.LOADING_COUPON,
    });

    axios
      .get(`${APP_URL}/en/api/coupon/${menuId}/${coupon.toUpperCase()}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          // Calculate discounts
          dispatch({
            type: types.SAVE_COUPON,
            coupon: response.data,
          });

          dispatch(calculateDiscount());
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          dispatch({
            type: types.INVALID_COUPON,
          });
        }
      });
  };
}

export const getDeliveryFees = (position, place, setAlertOpen) => {
  return async (dispatch, getState) => {
    const { menuInfo } = getState();
    const { id } = menuInfo.menuData;
    const { lat, lng } = position;

    dispatch({
      type: types.LOADING_DELIVERY_FEES,
    });

    axios
      .get(`${APP_URL}/en/api/delivery_zone/${lat},${lng}/${id}/`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const fees = response.data?.delivery_amount;
          dispatch(saveDeliveryFees(fees, place, position));
        }
      })
      .catch((error) => {
        dispatch(resetDelivery());
        setAlertOpen(true);
      });
  };
};

export const saveDeliveryFees = (delivery_fees, place, location) => {
  return {
    type: types.SAVE_DELIVERY_FEES,
    deliveryFees: delivery_fees,
    deliveryPlace: place,
    deliveryLocation: location,
  };
};

export const resetDelivery = () => {
  return {
    type: types.RESET_DELIVERY_LOCATION,
  };
};

export const saveDeliveryAddress = (
  flat_no,
  building_premise,
  area_street,
  landmark,
  history,
  saveAddress
) => {
  return (dispatch, getState) => {
    const { cartInfo } = getState();
    const { deliveryPlace, deliveryLocation } = cartInfo;
    const { formatted_address } = deliveryPlace;
    let address = formatted_address.split(" - ");
    if (!!landmark) {
      address.unshift(landmark);
    }
    address.unshift(area_street);
    address.unshift(building_premise);
    address.unshift(flat_no);

    const deliveryFormattedAddress = address.join(", ");

    dispatch({
      type: types.SAVE_DELIVERY_ADDRESS,
      deliveryFormattedAddress: deliveryFormattedAddress,
      deliveryFlatNo: flat_no,
      deliveryBuildingPremise: building_premise,
      deliveryAreaStreet: area_street,
      deliveryLandmark: landmark,
    });

    if (saveAddress) {
      dispatch({
        type: types.SAVE_ADDRESS_HISTORY,
        uuid: uuidv4(),
        deliveryLocation: deliveryLocation,
        deliveryFormattedAddress: deliveryFormattedAddress,
        deliveryFlatNo: flat_no,
        deliveryBuildingPremise: building_premise,
        deliveryAreaStreet: area_street,
        deliveryLandmark: landmark,
      });
    }

    history.push("/cart");
  };
};

export const savePaymentType = (payment_type) => {
  return {
    type: types.SAVE_PAYMENT_TYPE,
    paymentType: payment_type,
  };
};

export const saveOrderComments = (comments) => {
  return {
    type: types.SAVE_ORDER_COMMENTS,
    orderComments: comments,
  };
};

export const enableWishlist = () => {
  return {
    type: types.ENABLE_WISHLIST,
  };
};

export const saveOrderType = (type) => {
  return {
    type: types.SAVE_ORDER_TYPE,
    orderType: type,
  };
};

export const saveCustomerName = (name) => {
  return {
    type: types.SAVE_CUSTOMER_NAME,
    name,
  };
};

export const saveCustomerEmail = (email) => {
  return {
    type: types.SAVE_CUSTOMER_EMAIL,
    email,
  };
};

export const saveCustomerPhone = (phone) => {
  return {
    type: types.SAVE_CUSTOMER_PHONE,
    phone,
  };
};

export const saveCarNumber = (carNumber) => {
  return {
    type: types.SAVE_CAR_NUMBER,
    carNumber,
  };
};

export const deleteCoupon = () => {
  return {
    type: types.DELETE_COUPON,
  };
};

export const orderClear = () => {
  return {
    type: types.ORDER_CLEAR,
  };
};

export const afSetOrderSubmitted = (order_id) => {
  return {
    type: types.AF_ACTIVE_ORDER,
    order_id,
  };
};

export const afSetOrderAccepted = (value) => {
  return {
    type: types.AF_ORDER_ACCEPTED,
    status: value,
  };
};

export function addCart(
  uuid,
  id,
  category,
  name,
  sku,
  image,
  price,
  qty,
  special_instructions,
  variations,
  combo_groups,
  max_qty
) {
  return async (dispatch) => {
    dispatch({
      type: types.ADD_CART,
      uuid,
      id,
      category,
      name,
      sku,
      image,
      price,
      qty,
      special_instructions,
      variations,
      combo_groups,
      max_qty,
    });

    dispatch(calculateDiscount());
    dispatch(getIikoLoyaltyPromotions());
  };
}

export function removeCart(uuid) {
  return async (dispatch) => {
    dispatch({ type: types.REMOVE_CART, uuid });
    dispatch(calculateDiscount());
    dispatch(getIikoLoyaltyPromotions());
  };
}

export function updateCartQty(uuid, qty) {
  return async (dispatch) => {
    dispatch({
      type: types.UPDATE_CART_QTY,
      uuid,
      qty,
    });
    dispatch(calculateDiscount());
    dispatch(getIikoLoyaltyPromotions());
  };
}

export const saveCartTotals = (taxes, subTotal, total) => {
  return (dispatch, getState) => {
    const { menuInfo, cartInfo } = getState();
    const { paymentType } = cartInfo;
    const { payment_enabled } = menuInfo?.menuData;

    dispatch({
      type: types.SAVE_CART_TOTALS,
      taxes,
      subTotal,
      total,
    });

    if (Number(total) > 0 && payment_enabled && paymentType === "") {
      // Set payment type to card if total is greater than 0 and if online payments is enabled
      dispatch(savePaymentType("card"));
    } else if (Number(total) > 0 && !payment_enabled && paymentType === "") {
      dispatch(savePaymentType("cash"));
    } else if (Number(total) === 0) {
      dispatch(savePaymentType("cash"));
    }
  };
};

export const saveMinOrderAmountError = (error) => {
  return {
    type: types.SAVE_MIN_ORDER_AMOUNT_ERROR,
    minOrderAmountError: error,
  };
};

export const saveOrderLocation = (location, locationType) => {
  return {
    type: types.SAVE_ORDER_LOCATION,
    orderLocation: location,
    orderLocationType: locationType,
  };
};

export function removeAddress(uuid) {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_ADDRESS, uuid });
  };
}

export function selectAddress(address) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_ADDRESS,
      deliveryFlatNo: address.deliveryFlatNo,
      deliveryBuildingPremise: address.deliveryBuildingPremise,
      deliveryAreaStreet: address.deliveryAreaStreet,
      deliveryLandmark: address.deliveryLandmark,
    });
  };
}

export function addItemsToOrder(order_uuid, history) {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ADDING_ITEMS_TO_ORDER,
    });
    const { menuInfo, cartInfo } = getState();
    const {
      currency,
      price_decimal_places,
      al_futtaim_enabled,
    } = menuInfo.menuData;

    const { products } = cartInfo;

    const lineItems = products.map((item) => {
      let variations = [];
      item.variations.map((variation) =>
        variation.values
          .filter((v) => v.selected)
          .map((vv) => (vv.id ? variations.push(vv.id) : null))
      );

      return {
        product: item.id,
        product_sku: item.sku,
        product_name: item.name,
        product_variation: item.variations
          .map(
            (variation) =>
              `${variation.name}: ${variation.values
                .filter((v) => v.selected)
                .map((v) => v.name)
                .join(", ")}`
          )
          .join("\n    "),
        variations: variations,
        combo_groups: item.combo_groups,
        product_price: parseFloat(item.price).toFixed(price_decimal_places),
        product_price_currency: currency,
        quantity: item.qty,
        special_instructions: item.special_instructions,
        cart_uuid: item.uuid,
      };
    });

    axios
      .patch(
        `${APP_URL}/en/api/order/${order_uuid}/`,
        JSON.stringify({
          line_items: lineItems,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        history.push({
          pathname: `/order/${response.data.uuid}`,
          state: { order: response.data },
        });
        if (al_futtaim_enabled) {
          dispatch(afSetOrderSubmitted(response.data.uuid));
        } else {
          dispatch({
            type: types.ORDER_CLEAR,
          });
        }
      })
      .catch((error) => {
        // Error

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          console.error(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error", error.message);
        }
        //console.log(error.config);
      })
      .finally(() => {
        dispatch({
          type: types.ADDED_ITEMS_TO_ORDER,
        });
      });
  };
}

export function submitOrder(data, history) {
  return async (dispatch, getState) => {
    const { menuInfo, cartInfo } = getState();

    const {
      id: menuId,
      name: menuName,
      currency,
      payment_enabled,
      enable_whatsapp_order,
      whatsapp_number,
      enable_user_send_whatsapp,
      enable_address_google_maps,
      price_decimal_places,
      ui_strings,
      al_futtaim_enabled,
      menu_country,
    } = menuInfo.menuData;

    const {
      products,
      coupon,
      discount,
      deliveryFormattedAddress,
      deliveryLocation,
      deliveryPlace,
      deliveryFlatNo,
      deliveryBuildingPremise,
      deliveryAreaStreet,
      deliveryLandmark,
      orderLocation,
      orderLocationType,
      orderComments,
      orderType,
      deliveryFees,
      subTotal,
      total,
      paymentType,
      taxes,
      employeeID,
      iikoLoyaltyDiscounts,
    } = cartInfo;

    dispatch({
      type: types.ORDER_SUBMITTING,
    });

    if (data?.name) {
      dispatch(saveCustomerName(data.name));
    }

    if (data?.email) {
      dispatch(saveCustomerEmail(data.email));
    }

    if (data?.phone) {
      dispatch(
        saveCustomerPhone(formatNumber(data.phone, menu_country, "E.164"))
      );
    }

    if (data?.carNumber) {
      dispatch(saveCarNumber(data.carNumber));
    }

    const lineItems = products.map((item) => {
      let variations = [];
      item.variations.map((variation) =>
        variation.values
          .filter((v) => v.selected)
          .map((vv) => (vv.id ? variations.push(vv.id) : null))
      );

      return {
        product: item.id,
        product_sku: item.sku,
        product_name: item.name,
        product_variation: item.variations
          .map(
            (variation) =>
              `${variation.name}: ${variation.values
                .filter((v) => v.selected)
                .map((v) => v.name)
                .join(", ")}`
          )
          .join("\n    "),
        variations: variations,
        combo_groups: item.combo_groups,
        product_price: parseFloat(item.price).toFixed(price_decimal_places),
        product_price_currency: currency,
        quantity: item.qty,
        special_instructions: item.special_instructions,
        cart_uuid: item.uuid,
      };
    });

    const customer_name =
      orderLocation && orderLocationType
        ? `${orderLocationType}# ${orderLocation}`
        : data?.name
        ? data.name
        : "eMenu";

    const customer_email = data?.email
      ? data.email
      : orderLocation && orderLocationType
      ? "info@emenu.ae"
      : "info@emenu.ae";

    const customer_phone = data?.phone
      ? formatNumber(data.phone, menu_country, "E.164")
      : orderLocation && orderLocationType
      ? "+97143793455"
      : "+97143793455";

    const custom_datetime = data.customDateTime
      ? data.customDateTime
      : new Date();

    const order_type =
      orderType === "takeaway"
        ? ui_strings?.ui_str_takeaway || "Takeaway"
        : orderType === "dine_in"
        ? ui_strings?.ui_str_dine_in || "Dine In"
        : orderType === "drive_thru"
        ? ui_strings?.ui_str_drive_thru || "Drive Thru"
        : orderType;

    const payment_type =
      paymentType === "cash"
        ? "Cash"
        : paymentType === "card"
        ? "Online Payment"
        : "Card Machine";

    const delivery_address =
      orderType === "delivery" && deliveryFormattedAddress
        ? deliveryFormattedAddress
        : orderType === "delivery" && data?.address
        ? data.address
        : orderType === "drive_thru" && data?.carNumber
        ? `Drive-Thru\nCar Number: ${data.carNumber}`
        : orderType === "dine_in" && orderLocation && orderLocationType
        ? `${order_type}\n${orderLocationType}# ${orderLocation}`
        : order_type;

    const delivery_fees = orderType === "delivery" ? deliveryFees : 0;

    const address_coordinates = {
      type: "Point",
      coordinates: [
        orderType === "delivery" && deliveryLocation?.lng
          ? deliveryLocation.lng
          : 0,
        orderType === "delivery" && deliveryLocation?.lat
          ? deliveryLocation.lat
          : 0,
      ],
    };

    // Total cart items is number of products times the qty
    const totalCartItems = lineItems.reduce(
      (prev, lineItem) => prev + lineItem.quantity,
      0
    );

    const cartSubTotalStr = ui_strings?.ui_str_cart_subtotal
      ? ui_strings.ui_str_cart_subtotal
          .replace(/items/g, totalCartItems > 1 ? "items" : "item")
          .replace(/%s/g, totalCartItems)
      : `Subtotal (${totalCartItems} items)`;

    let additional_data = {};

    if (!!employeeID) {
      additional_data.employee_id = employeeID;
    }

    if (iikoLoyaltyDiscounts.length > 0) {
      additional_data.iiko_loyalty_discounts = iikoLoyaltyDiscounts;
    }

    axios
      .post(
        `${APP_URL}/en/api/order/`,
        JSON.stringify({
          menu: menuId,
          customer_name: customer_name,
          address: delivery_address,
          address_coordinates: address_coordinates,
          address_google_place_data: deliveryPlace,
          address_flat_no: deliveryFlatNo,
          address_building_premise: deliveryBuildingPremise,
          address_area_street: deliveryAreaStreet,
          address_landmark: deliveryLandmark,
          email: customer_email,
          phone: customer_phone,
          order_comments: orderComments,
          order_type: orderType,
          delivery_fees: parseFloat(delivery_fees).toFixed(
            price_decimal_places
          ),
          delivery_fees_currency: currency,
          coupon: coupon?.id,
          discount: discount,
          taxes: taxes,
          sub_total: parseFloat(subTotal).toFixed(price_decimal_places),
          sub_total_currency: currency,
          total: parseFloat(total).toFixed(price_decimal_places),
          total_currency: currency,
          delivery_datetime: custom_datetime,
          payment_type: paymentType,
          line_items: lineItems,
          additional_data: additional_data,
        }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.status === 201) {
          // Setup whatsapp msg if enabled
          if (
            !payment_enabled &&
            enable_whatsapp_order &&
            whatsapp_number &&
            enable_user_send_whatsapp
          ) {
            const whatsappText = `Menu: ${menuName}
Order Id: ${response.data.padded_order_id}
Name: ${customer_name}
Email: ${customer_email}
Phone: ${customer_phone}
Address: ${delivery_address} ${
              enable_address_google_maps && orderType === "delivery"
                ? `\nGoogle Maps: https://maps.google.com/maps?q=${deliveryLocation?.lat},${deliveryLocation?.lng}`
                : ``
            }
Order Comments: ${orderComments}
Payment Type: ${payment_type}
Items: ${lineItems
              .map(
                (item) => `
    Name: ${item.product_name}${
                  item.product_variation
                    ? `\n    ${item.product_variation}`
                    : ""
                }
    Price: ${item.product_price_currency}${parseFloat(
                  item.product_price
                ).toFixed(price_decimal_places)}
    Qty: ${item.quantity}
    Comments: ${item.special_instructions}`
              )
              .join("\n\n")}
\n${cartSubTotalStr}: ${currency}${parseFloat(subTotal).toFixed(
              price_decimal_places
            )}
${taxes
  .map(
    (tax) =>
      `${tax.name}: ${currency}${parseFloat(tax.amount).toFixed(
        price_decimal_places
      )}`
  )
  .join("\n")}\n${
              delivery_fees > 0
                ? `Delivery Fees: ${currency}${parseFloat(
                    delivery_fees
                  ).toFixed(price_decimal_places)}\n`
                : ``
            }${
              discount > 0
                ? `Discount: ${currency}${parseFloat(discount).toFixed(
                    price_decimal_places
                  )}\n`
                : ``
            }Total: ${currency}${parseFloat(total).toFixed(
              price_decimal_places
            )}`;

            const wEncText = encodeURI(whatsappText).replace(/#/g, "%23");
            window.open(
              `https://api.whatsapp.com/send?phone=${whatsapp_number.substring(
                1
              )}&text=${wEncText}`,
              "_blank"
            );
          }

          if (response.data?.payment_url) {
            dispatch({
              type: types.ORDER_REDIRECT,
              paymentUrl: response.data.payment_url,
            });
          } else {
            history.push({
              pathname: `/order/${response.data.uuid}`,
              state: { order: response.data },
            });
            if (al_futtaim_enabled) {
              dispatch(afSetOrderSubmitted(response.data.uuid));
            } else {
              dispatch({
                type: types.ORDER_CLEAR,
              });
            }
          }
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          dispatch({
            type: types.ORDER_ERROR,
            message: error.response.data,
          });
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
          dispatch({
            type: types.ORDER_ERROR,
            message: { error: "No response from server" },
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
          dispatch({
            type: types.ORDER_ERROR,
            message: { error: "Error setting up request" },
          });
        }
        //console.log(error.config);
      });
  };
}
