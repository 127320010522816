import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles, rgbToHex } from "@material-ui/core/styles";
// core components
import { FormProvider, useForm } from "react-hook-form";
import Header from "components/Emenu/Header/Header.js";
import { useSelector } from "react-redux";
import FormStyle from "./FormStyle";
import firebase from "../../constants/Firebase";
import axios from "axios";
import { APP_URL } from "../../constants/Api";
import { fontName, isEmpty } from "../../constants/utils";
import Loader from "react-loader-spinner";
import FormItem from "../../components/Emenu/Form/FormItem";
import Button from "../../components/CustomButtons/Button";
import emailConfirmation from "../../assets/lotties/16894-email-confirmation-sent.json";
import Lottie from "react-lottie-player";
import EmenuNavlink from "../../components/Emenu/Navlink/EmenuNavlink";
import { replaceColor } from "lottie-colorify";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(FormStyle);

export default function FormPage(props) {
  const { match, location } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { selected_language } = menuInfo;
  const { forms, ui_strings, theme_data, license } = menuInfo.menuData;
  const currentForm = location.formData
    ? location.formData
    : forms.length > 0
    ? forms.filter((form) => form.id === match.params.id)[0]
    : null;
  const [formData, setFormData] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = "#efefef";
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor = "#efefef";
    }
  }, []);

  useEffect(() => {
    if (currentForm && selected_language) {
      setIsSubmitSuccessful(false);

      // Get Form Fields
      axios
        .get(
          `${APP_URL}/${selected_language}/api/feedback/${currentForm.id}/`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setFormData(response.data);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
  }, [currentForm, selected_language]);

  useEffect(() => {
    if (formData?.id) {
      // Analytics
      if (license) {
        firebase.analytics().logEvent("info_form_view", {
          license: license,
          page_name: formData.name,
          page_id: formData.id,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const classes = useStyles();

  const onSubmit = (data) => {
    // Get Form Fields
    if (isValid && formData?.id) {
      const submissionData = {};
      submissionData["feedback_id"] = formData?.id;
      submissionData["submission"] = data;

      axios
        .post(
          `${APP_URL}/${selected_language}/api/submission/`,
          JSON.stringify(submissionData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 201) {
            setIsSubmitSuccessful(true);
            setSuccessMessage(response.data.message);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
  };

  const methods = useForm({ mode: "onChange" });
  const { isValid, isSubmitting } = methods.formState;

  const successStyle = {
    color: theme_data?.feedback_success_text_color,
    fontSize: Number(theme_data?.feedback_success_font_size),
    ...fontName(theme_data?.feedback_success_font_family),
  };

  const formButtonStyle = {
    backgroundColor: theme_data?.feedback_submit_btn_color,
    color: theme_data?.feedback_submit_btn_text_color,
    fontSize: Number(theme_data?.feedback_submit_btn_font_size),
    ...fontName(theme_data?.feedback_submit_btn_font_family),
    marginTop: 40,
  };

  const backButtonTextStyle = {
    backgroundColor: theme_data?.cart_screen_continue_shopping_btn_color,
    color: theme_data?.cart_screen_continue_shopping_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_continue_shopping_btn_font_size),
    ...fontName(theme_data?.cart_screen_continue_shopping_btn_font_family),
  };

  const isHex = (color) => {
    return /^#[0-9A-F]{6}$/i.test(color);
  };

  return currentForm && !isEmpty(formData?.form_data) ? (
    <div className={classes.mainContainer}>
      <Header brand={currentForm.name} />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${currentForm.name} | ${menuInfo.menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div className={classNames(classes.toolbar, classes.main)}>
        {isSubmitSuccessful ? (
          <div className={classes.success}>
            <Lottie
              loop={false}
              speed={3}
              play={isSubmitSuccessful}
              animationData={replaceColor(
                [204, 204, 204],
                isHex(theme_data?.feedback_success_text_color)
                  ? theme_data.feedback_success_text_color
                  : rgbToHex(theme_data?.feedback_success_text_color).slice(
                      0,
                      -2
                    ),
                emailConfirmation
              )}
              style={{ width: "50%", height: "50%" }}
            />
            <div className={classes.successMessage}>
              <div
                style={successStyle}
                dangerouslySetInnerHTML={{ __html: successMessage }}
              ></div>
            </div>
            <div style={{ marginTop: 30 }}>
              <EmenuNavlink to={"/home"}>
                <Button fullWidth style={backButtonTextStyle}>
                  {ui_strings?.ui_str_continue_shopping
                    ? ui_strings.ui_str_continue_shopping
                    : "Back To Menu"}
                </Button>
              </EmenuNavlink>
            </div>
          </div>
        ) : (
          <FormProvider {...methods}>
            <div className={classes.container}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                className={classes.form}
              >
                {formData.form_data.map((item, index) => (
                  <FormItem item={item} key={index} />
                ))}
                <Button
                  onClick={methods.handleSubmit(onSubmit)}
                  fullWidth
                  style={formButtonStyle}
                  disabled={!isValid}
                >
                  {isSubmitting
                    ? ui_strings?.ui_str_feedback_submit
                      ? ui_strings.ui_str_feedback_submitting
                      : "Submitting"
                    : ui_strings?.ui_str_feedback_submit
                    ? ui_strings.ui_str_feedback_submit
                    : "Submit"}
                </Button>
              </form>
            </div>
          </FormProvider>
        )}
      </div>
    </div>
  ) : (
    <div>
      <Header brand="Loading..." />
      <div className={classNames(classes.toolbar, classes.main)}>
        <div className={classes.container}>
          <Loader
            type="Oval"
            height={50}
            width={50}
            className={classes.loader}
          />
        </div>
      </div>
    </div>
  );
}
