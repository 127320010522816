import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import RenderProducts from "./RenderProducts";

const useStyles = makeStyles(CategoryStyle);

export default function RenderCategory(props) {
  const { currentCategory, handleCartButton } = props;
  const classes = useStyles();

  const { description, products, view_format } = currentCategory;

  return (
    <div>
      {description && (
        <div
          className={classes.categoryDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      <RenderProducts
        products={products}
        handleCartButton={handleCartButton}
        view_format={view_format}
      />
    </div>
  );
}
