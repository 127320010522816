import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import SearchIcon from "@material-ui/icons/Search";
// core components
import styles from "./headerStyle";
import HeaderLinks from "./HeaderLinks";
import { useSelector } from "react-redux";
import { fontName } from "../../../constants/utils";
import { useHistory, useLocation } from "react-router-dom";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as OrderPlaced } from "../../../assets/svg/order-placed.svg";

const useStyles = makeStyles(styles);

export default function Header(props) {
  const history = useHistory();
  const location = useLocation();
  const loc = location.pathname.split("/");
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { theme_data } = menuInfo.menuData;
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  const { afActiveOrder, products } = cartInfo;

  useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    const totalCartItems = products.reduce(
      (prev, product) => prev + product.qty,
      0
    );
    setCartCount(totalCartItems);
  }, [products]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleBack = () => {
    history.goBack();
  };
  const handleCartButton = () => {
    if (!!afActiveOrder) {
      if (loc[1] !== `/order/${afActiveOrder}`) {
        history.push(`/order/${afActiveOrder}`);
      }
    } else {
      if (loc[1] !== "cart") {
        history.push("/cart");
      }
    }
  };

  const handleSearchButton = () => {
    if (loc[1] !== "search") {
      history.push("/search");
    }
  };

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;

    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { brand, color, absolute } = props;
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: true,
  });
  const rtlDrawerStyle = {
    left: "auto",
    right: 0,
    backgroundColor: theme_data?.drawer_bg_color,
  };

  const ltrDrawerStyle = {
    left: 0,
    right: "auto",
    backgroundColor: theme_data?.drawer_bg_color,
  };

  return (
    <AppBar
      style={{
        backgroundColor: theme_data?.header_bg_color,
        width: "inherit",
        maxWidth: "inherit",
        left: ["ar", "he"].includes(menuInfo.selected_language) ? "auto" : 0,
      }}
      className={appBarClasses}
    >
      <Toolbar className={classes.container}>
        <div className={classes.iconContainer}>
          <IconButton
            style={{ color: theme_data?.header_menu_icon_color, minWidth: 48 }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
          >
            <Menu />
          </IconButton>
          {props.goback && (
            <IconButton
              style={{
                color: theme_data?.header_menu_icon_color,
                minWidth: 48,
              }}
              color="inherit"
              aria-label="go back"
              onClick={handleBack}
            >
              {["ar", "he"].includes(menuInfo.selected_language) ? (
                <ArrowForwardIosIcon />
              ) : (
                <ArrowBackIosIcon />
              )}
            </IconButton>
          )}
        </div>
        <div
          style={{
            ...fontName(theme_data?.header_title_font_family),
            color: theme_data?.header_title_text_color,
            fontSize: Number(theme_data?.header_title_font_size),
          }}
          className={classes.brandName}
        >
          {brand}
        </div>
        <div className={classes.iconContainer}>
          {menuInfo.menuData.enable_search && (
            <IconButton
              style={{
                color: theme_data?.header_menu_icon_color,
                minWidth: 48,
              }}
              color="inherit"
              aria-label="search"
              onClick={handleSearchButton}
              className={"search-icon"}
            >
              <SearchIcon />
            </IconButton>
          )}
          {menuInfo.menuData.enable_order && (
            <IconButton
              style={{
                color: theme_data?.header_menu_icon_color,
                minWidth: 48,
                opacity: menuInfo?.isOpen ? 1 : 0.5,
              }}
              color="inherit"
              aria-label="cart"
              onClick={handleCartButton}
              className={"cart-icon"}
              disabled={!menuInfo.isOpen}
            >
              {!!afActiveOrder ? (
                <SvgIcon
                  component={OrderPlaced}
                  style={{
                    fontSize: "24px",
                    padding: 0,
                    color: "#5CBF38",
                    position: "absolute",
                    marginTop: "2px",
                    left: "-2px",
                  }}
                />
              ) : !afActiveOrder && cartCount > 0 ? (
                <div className={classes.cartCounter}>{cartCount}</div>
              ) : null}

              <ShoppingCartIcon
                color={!!afActiveOrder ? "disabled" : "inherit"}
              />
            </IconButton>
          )}
        </div>
      </Toolbar>

      <Drawer
        variant="temporary"
        anchor={
          ["ar", "he"].includes(menuInfo.selected_language) ? "right" : "left"
        }
        open={mobileOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{
          style: ["ar", "he"].includes(menuInfo.selected_language)
            ? rtlDrawerStyle
            : ltrDrawerStyle,
        }}
        onClose={handleDrawerToggle}
      >
        <div className={classes.appResponsive}>
          {
            <HeaderLinks
              closeDrawer={handleDrawerToggle}
              dropdownHoverColor="info"
            />
          }
        </div>
      </Drawer>
    </AppBar>
  );
}

Header.defaultProp = {
  color: "white",
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark",
  ]),
  links: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
    ]).isRequired,
  }),
};
