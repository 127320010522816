import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

const FormSelect = (props) => {
  const { control, formState } = useFormContext();
  const {
    label,
    name,
    options,
    multiple,
    placeholder,
    description,
    required,
  } = props;

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(description);

  useEffect(() => {
    if (formState.errors && formState.errors.hasOwnProperty(name)) {
      setMessage(formState.errors[name].message);
      setIsError(true);
    } else {
      setMessage(description);
      setIsError(false);
    }
  }, [formState, name, description]);

  return (
    <FormControl required={required} fullWidth={true}>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        render={({ value, onChange }, { invalid }) => (
          <Select
            id={name}
            multiple={multiple}
            error={invalid}
            value={value}
            onChange={onChange}
            required={required}
          >
            {!multiple ? (
              <MenuItem value="">
                <em>{placeholder}</em>
              </MenuItem>
            ) : null}
            {options.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
        control={control}
        defaultValue={multiple ? [] : ""}
        rules={{
          required: {
            value: required,
            message: `${label} is a required field`,
          },
          validate: (value) => {
            return (required || multiple) && value.length > 0
              ? true
              : !required
              ? true
              : `${label} is a required field`;
          },
        }}
      />
      <FormHelperText className={isError ? "Mui-error" : ""}>
        {message}
      </FormHelperText>
    </FormControl>
  );
};

export default FormSelect;
