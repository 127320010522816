/* eslint-disable */
import React, { useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link, NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import styles from "./headerLinksStyle";
import { useDispatch, useSelector } from "react-redux";
import { colorToRGBA, fontName, is_open } from "../../../constants/utils";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import LanguageIcon from "@material-ui/icons/Language";
import { changeLanguage } from "../../../store/actions/menu";
import Icon from "@material-ui/core/Icon";
import emenuLogo from "../../../assets/img/emenu/emenu-logo.jpg";
import { css } from "@emotion/css";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    categories,
    pages,
    promotions,
    forms,
    timezone,
    ui_strings,
    theme_data,
    license,
    name,
  } = menuInfo.menuData;
  const enabledForms = forms?.filter((form) => form.enable);
  const enabledPromotions = promotions?.filter((promotion) => promotion.enable);
  const dispatch = useDispatch();
  const mainCategories = categories.filter(
    (category) =>
      !category.parent &&
      !category.disable_link &&
      !category.external_link &&
      is_open(category.category_hours, [], timezone)
  );
  const logoImage = menuInfo.menuData.logo_image.logo
    ? menuInfo.menuData.logo_image.logo
    : emenuLogo;
  const itemStyle = {
    color: theme_data?.drawer_list_item_text_color,
    fontSize: Number(theme_data?.drawer_list_item_font_size),
    ...fontName(theme_data?.drawer_list_item_font_family),
  };
  const itemActiveStyle = {
    backgroundColor: theme_data?.drawer_list_item_selected_bg_color,
    color: theme_data?.drawer_list_item_selected_text_color,
    fontSize: Number(theme_data?.drawer_list_item_selected_font_size),
    ...fontName(theme_data?.drawer_list_item_selected_font_family),
  };

  const [lang, setLang] = useState(menuInfo.selected_language);

  const handleLanguageSelection = (e) => {
    setLang(e.target.value);
    dispatch(changeLanguage(license, e.target.value));
  };

  return (
    <div>
      <div className={classes.menu}>
        <Link to="/">
          <img src={logoImage} className={classes.topLogo} alt={name} />
        </Link>
        {menuInfo.languages.length > 1 ? (
          <div className={classes.languageSelector}>
            <Select
              value={lang}
              onChange={handleLanguageSelection}
              inputProps={{
                name: "lang",
              }}
              style={{
                color: theme_data?.drawer_language_dropdown_text_color,
                fontSize: Number(
                  theme_data?.drawer_language_dropdown_font_size
                ),
                ...fontName(theme_data?.drawer_language_dropdown_font_family),
              }}
              className={css`
                &::after,
                &::before {
                  border-bottom-color: ${theme_data?.drawer_language_icon_color};
                }
              `}
            >
              {menuInfo.languages.map((x, i) => (
                <MenuItem key={i} value={x.code}>
                  {x.name_local}
                </MenuItem>
              ))}
            </Select>

            <Icon style={{ color: theme_data?.drawer_language_icon_color }}>
              <LanguageIcon />
            </Icon>
          </div>
        ) : null}

        <List className={classes.list}>
          <ListItem onClick={props.closeDrawer} className={classes.listItem}>
            <NavLink
              to="/home"
              className={classes.dropdownLink}
              exact
              activeStyle={itemActiveStyle}
              style={itemStyle}
            >
              {ui_strings?.ui_str_home ? ui_strings.ui_str_home : "Home"}
            </NavLink>
          </ListItem>
          {mainCategories.map((category) => (
            <ListItem
              onClick={props.closeDrawer}
              key={category.id}
              className={classes.listItem}
            >
              <NavLink
                to={{
                  pathname: `/category/${category.id}`,
                  categoryData: category,
                }}
                className={classes.dropdownLink}
                exact
                activeStyle={itemActiveStyle}
                style={itemStyle}
              >
                {category.name}
              </NavLink>
            </ListItem>
          ))}
        </List>

        {enabledPromotions?.length > 0 ? (
          <div>
            <Divider />
            <List className={classes.list + " " + classes.mlAuto}>
              <ListItem
                onClick={props.closeDrawer}
                className={classes.listItem}
              >
                <NavLink
                  to={{
                    pathname: `/promotions`,
                    promotionData: enabledPromotions,
                  }}
                  className={classes.dropdownLink}
                  exact
                  activeStyle={itemActiveStyle}
                  style={itemStyle}
                >
                  {ui_strings?.ui_str_promotions
                    ? ui_strings.ui_str_promotions
                    : "Promotions"}
                </NavLink>
              </ListItem>
            </List>
          </div>
        ) : null}

        {pages?.length > 0 ? <Divider /> : null}
        <List className={classes.list + " " + classes.mlAuto}>
          {pages?.map((page) => (
            <ListItem
              onClick={props.closeDrawer}
              key={page.id}
              className={classes.listItem}
            >
              <NavLink
                to={{ pathname: `/page/${page.id}`, pageData: page }}
                className={classes.dropdownLink}
                exact
                activeStyle={itemActiveStyle}
                style={itemStyle}
              >
                {page.title}
              </NavLink>
            </ListItem>
          ))}
        </List>
        {enabledForms?.length > 0 ? <Divider /> : null}
        <List className={classes.list + " " + classes.mlAuto}>
          {enabledForms?.map((form) => (
            <ListItem
              onClick={props.closeDrawer}
              key={form.id}
              className={classes.listItem}
            >
              <NavLink
                to={{ pathname: `/form/${form.id}`, formData: form }}
                className={classes.dropdownLink}
                exact
                activeStyle={itemActiveStyle}
                style={itemStyle}
              >
                {form.name}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </div>
      <div
        className={classes.version}
        style={{
          backgroundImage: `linear-gradient(${colorToRGBA(
            theme_data?.drawer_bg_color,
            0.8
          )}, ${theme_data?.drawer_bg_color})`,
          color: theme_data?.drawer_list_item_text_color,
        }}
      >
        <div>v. {process.env.REACT_APP_VERSION}</div>
      </div>
    </div>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
