import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Rating from "@material-ui/lab/Rating";
import { withStyles } from "@material-ui/core/styles";

const FormRadio = (props) => {
  const { control, formState } = useFormContext();
  const { label, name, helperText, required, numStars } = props;

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(helperText);

  useEffect(() => {
    if (formState.errors && formState.errors.hasOwnProperty(name)) {
      setMessage(formState.errors[name].message);
      setIsError(true);
    } else {
      setMessage(helperText);
      setIsError(false);
    }
  }, [formState, name, helperText]);

  const StyledRating = withStyles({
    label: {
      color: "#ffb400",
      fontSize: 35,
    },
  })(Rating);

  return (
    <FormControl fullWidth={true} required={required} component={"fieldset"}>
      <FormLabel component={"legend"}>{label}</FormLabel>
      <FormHelperText className={isError ? "Mui-error" : ""}>
        {message}
      </FormHelperText>
      <Controller
        name={name}
        render={({ value, onChange }, { invalid }) => (
          <StyledRating
            name={name}
            value={Number(value)}
            precision={1}
            emptyIcon={<StarBorderIcon fontSize="inherit" />}
            onChange={onChange}
            size={"large"}
            max={numStars}
          />
        )}
        control={control}
        defaultValue={""}
        rules={{
          required: {
            value: required,
            message: `${label} is a required field`,
          },
        }}
      />
    </FormControl>
  );
};

export default FormRadio;
