import React, { useEffect, useState } from "react";
import Button from "../../CustomButtons/Button";
import { fontName, isEmpty } from "../../../constants/utils";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DeliveryStyle from "../../../views/DeliveryPage/DeliveryStyle";
import { Card } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import { useHistory } from "react-router-dom";
import {
  getDeliveryFees,
  resetDelivery,
  saveDeliveryAddress,
} from "../../../store/actions/cart";
import { css } from "@emotion/css";
import CustomInput from "../../CustomInput/CustomInput";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(DeliveryStyle);

function MapConfirmButton(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedPlace, defaultPosition, setAlertOpen } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);

  const { ui_strings, theme_data } = menuInfo.menuData;
  const {
    loadingDeliveryFees,
    deliveryLocationConfirmed,
    deliveryFlatNo,
    deliveryBuildingPremise,
    deliveryAreaStreet,
    deliveryLandmark,
  } = cartInfo;

  const [flat_no, set_flat_no] = useState(deliveryFlatNo);
  const [building_premise, set_building_premise] = useState(
    deliveryBuildingPremise
  );
  const [area_street, set_area_street] = useState(deliveryAreaStreet);
  const [landmark, set_landmark] = useState(deliveryLandmark);
  const [validAddress, setValidAddress] = useState(false);
  const [saveAddress, setSaveAddress] = useState(false);

  const buttonStyle = {
    backgroundColor: theme_data?.cart_screen_continue_shopping_btn_color,
    color: theme_data?.cart_screen_continue_shopping_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_continue_shopping_btn_font_size),
    ...fontName(theme_data?.cart_screen_continue_shopping_btn_font_family),
  };

  const confirmLocation = () => {
    dispatch(getDeliveryFees(defaultPosition, selectedPlace, setAlertOpen));
  };

  const confirmAddressDetails = () => {
    if (validAddress) {
      dispatch(
        saveDeliveryAddress(
          flat_no,
          building_premise,
          area_street,
          landmark,
          history,
          saveAddress
        )
      );
    }
  };

  const changeLocation = () => {
    dispatch(resetDelivery());
  };

  useEffect(() => {
    if (flat_no && building_premise && area_street) {
      setValidAddress(true);
    } else {
      setValidAddress(false);
    }
  }, [flat_no, building_premise, area_street, landmark]);

  useEffect(() => {
    set_flat_no(deliveryFlatNo);
    set_building_premise(deliveryBuildingPremise);
    set_area_street(deliveryAreaStreet);
    set_landmark(deliveryLandmark);
  }, [
    deliveryFlatNo,
    deliveryBuildingPremise,
    deliveryAreaStreet,
    deliveryLandmark,
  ]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "flat_no":
        set_flat_no(value);
        break;
      case "building_premise":
        set_building_premise(value);
        break;
      case "area_street":
        set_area_street(value);
        break;
      case "landmark":
        set_landmark(value);
        break;
      case "save_address":
        setSaveAddress(!saveAddress);
        break;
      default:
        break;
    }
  };

  const renderForm = () => {
    return (
      <>
        <CustomInput
          id={"flat_no"}
          labelText={
            ui_strings?.ui_str_cart_flat_no
              ? ui_strings.ui_str_cart_flat_no
              : "House No. / Flat No."
          }
          formControlProps={{
            fullWidth: true,
            className: classes.formControl,
          }}
          success={!!flat_no}
          inputProps={{
            value: flat_no,
            type: "text",
            onChange: handleInputChange,
            className: css`
              &::after {
                border-bottom: 2px solid
                  ${theme_data?.cart_screen_form_input_border_color};
              }
            `,
          }}
        />
        <CustomInput
          id={"building_premise"}
          labelText={
            ui_strings?.ui_str_cart_building_premise
              ? ui_strings.ui_str_cart_building_premise
              : "Building / Premise Name"
          }
          formControlProps={{ fullWidth: true, className: classes.formControl }}
          success={!!building_premise}
          inputProps={{
            value: building_premise,
            type: "text",
            onChange: handleInputChange,
            className: css`
              &::after {
                border-bottom: 2px solid
                  ${theme_data?.cart_screen_form_input_border_color};
              }
            `,
          }}
        />
        <CustomInput
          id={"area_street"}
          labelText={
            ui_strings?.ui_str_cart_area_street
              ? ui_strings.ui_str_cart_area_street
              : "Area / Street"
          }
          formControlProps={{ fullWidth: true, className: classes.formControl }}
          success={!!area_street}
          inputProps={{
            value: area_street,
            type: "text",
            onChange: handleInputChange,
            className: css`
              &::after {
                border-bottom: 2px solid
                  ${theme_data?.cart_screen_form_input_border_color};
              }
            `,
          }}
        />
        <CustomInput
          id={"landmark"}
          labelText={
            ui_strings?.ui_str_cart_landmark
              ? ui_strings.ui_str_cart_landmark
              : "Landmark (optional)"
          }
          formControlProps={{ fullWidth: true }}
          success={!!landmark}
          inputProps={{
            value: landmark,
            type: "text",
            onChange: handleInputChange,
            className: css`
              &::after {
                border-bottom: 2px solid
                  ${theme_data?.cart_screen_form_input_border_color};
              }
            `,
          }}
        />
        <FormControl fullWidth>
          <FormControlLabel
            label={
              <Typography style={{ fontSize: 14 }}>Save Address</Typography>
            }
            control={
              <Checkbox
                id={"save_address"}
                checked={saveAddress}
                value={saveAddress}
                onChange={handleInputChange}
              />
            }
          />
        </FormControl>
        <Button
          fullWidth
          className={classes.menuButton}
          style={buttonStyle}
          disabled={!validAddress}
          onClick={confirmAddressDetails}
        >
          {ui_strings?.ui_str_map_confirm_location_continue
            ? ui_strings.ui_str_map_confirm_location_continue
            : "Continue"}
        </Button>
      </>
    );
  };

  return (
    <Card className={classes.mapCard}>
      {selectedPlace?.formatted_address && (
        <div className={classes.selectedAddressContainer}>
          <RoomIcon
            style={{
              color: "#E24839",
            }}
          />
          <div className={classes.selectedAddress}>
            {selectedPlace?.formatted_address}
          </div>
          <Button
            size={"sm"}
            disabled={!deliveryLocationConfirmed}
            style={{
              backgroundColor: "#E24839",
              display: !deliveryLocationConfirmed ? "none" : "block",
              marginLeft: 10,
            }}
            onClick={changeLocation}
          >
            {ui_strings?.ui_str_map_confirm_location_change
              ? ui_strings.ui_str_map_confirm_location_change
              : "Change"}
          </Button>
        </div>
      )}
      {deliveryLocationConfirmed ? (
        renderForm()
      ) : (
        <Button
          fullWidth
          className={classes.menuButton}
          style={buttonStyle}
          disabled={loadingDeliveryFees || isEmpty(defaultPosition)}
          onClick={confirmLocation}
        >
          {isEmpty(defaultPosition)
            ? ui_strings?.ui_str_map_loading
              ? ui_strings.ui_str_map_loading
              : "Loading Map ..."
            : loadingDeliveryFees
            ? ui_strings?.ui_str_map_confirm_location_loading
              ? ui_strings.ui_str_map_confirm_location_loading
              : "Confirming Location ..."
            : ui_strings?.ui_str_map_confirm_location
            ? ui_strings.ui_str_map_confirm_location
            : "Confirm Location"}
        </Button>
      )}
    </Card>
  );
}

export default MapConfirmButton;
