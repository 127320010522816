import React, { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const FormRadio = (props) => {
  const { control, formState, setValue } = useFormContext();
  const { label, name, options, inline, description, required, other } = props;

  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState(description);

  const [otherValue, setOtherValue] = useState("");

  const otherValueChangeHandler = (e) => {
    let arr = options.map((o) => o.value);
    arr.push("00-other");

    if (arr.includes(e.target.value)) {
      setOtherValue("");
    } else {
      setOtherValue(e.target.value);
    }
  };

  const validateValue = (value) => {
    if (required) {
      if (value === "00-other") {
        return otherValue !== "" ? true : "Please enter other value";
      } else {
        return value ? true : `${label} is a required field`;
      }
    } else {
      if (value === "00-other") {
        return otherValue !== "" ? true : "Please enter other value";
      }
    }
    return true;
  };

  useEffect(() => {
    if (otherValue !== "") {
      setValue(name, otherValue);
    } else {
      setValue(name, "");
    }
    // eslint-disable-next-line
  }, [otherValue]);

  useEffect(() => {
    if (formState.errors && formState.errors.hasOwnProperty(name)) {
      setMessage(formState.errors[name].message);
      setIsError(true);
    } else {
      setMessage(description);
      setIsError(false);
    }
  }, [formState, name, description, otherValue]);

  return (
    <FormControl fullWidth={true} required={required} component={"fieldset"}>
      <FormLabel component={"legend"}>{label}</FormLabel>
      <FormHelperText className={isError ? "Mui-error" : ""}>
        {message}
      </FormHelperText>
      <Controller
        name={name}
        render={({ value, onChange }, { invalid }) => (
          <RadioGroup
            id={name}
            name={name}
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
              otherValueChangeHandler(e);
            }}
            row={inline}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                control={<Radio />}
                label={option.label}
                value={option.value}
              />
            ))}
            {other ? (
              <FormControlLabel
                control={<Radio />}
                label={"Other"}
                value={"00-other"}
                disabled={!!otherValue}
                style={{ display: otherValue ? "none" : "inherit" }}
              />
            ) : null}
            {other && value && !options.map((o) => o.value).includes(value) ? (
              <TextField
                error={invalid}
                fullWidth={true}
                label={"Other"}
                name={"other-text"}
                value={otherValue}
                onChange={(e) => {
                  onChange(e.target.value);
                  otherValueChangeHandler(e);
                }}
              />
            ) : null}
          </RadioGroup>
        )}
        control={control}
        defaultValue={""}
        rules={{
          required: {
            value: required,
            message: `${label} is a required field`,
          },
          validate: { validateValue },
        }}
      />
    </FormControl>
  );
};

export default FormRadio;
