import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import OrderTotalsItems from "./OrderTotalsItems";
import { useDispatch, useSelector } from "react-redux";
import OrderStyle from "../../../views/OrderPage/OrderStyle";
import { useHistory } from "react-router-dom";
import {
  addItemsToOrder,
  getIikoActiveOrders,
} from "../../../store/actions/cart";

const useStyles = makeStyles(OrderStyle);

const ConfirmationDialogRaw = (props) => {
  const classes = useStyles();
  const { onCancel, open, lineItems, orderUUID, ...other } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const { addingItemToOrder } = cartInfo;
  const { currency } = menuInfo.menuData;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancel = () => {
    // Redirect to cart
    onCancel();
  };

  const handleMerge = () => {
    dispatch(addItemsToOrder(orderUUID, history));
  };

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">Active Order</DialogTitle>
      <DialogContent dividers>
        There is an existing order on this table with the following items:
        {lineItems?.length > 0 && (
          <table className={classes.lineItemTable}>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Qty</th>
                <th>Product</th>
                <th style={{ textAlign: "right" }}>
                  Price
                  <br />({currency})
                </th>
              </tr>
            </thead>
            <tbody>
              <OrderTotalsItems line_items={lineItems} />
            </tbody>
          </table>
        )}
        <div style={{ marginTop: "20px" }}>
          Merge your cart items with the existing order?
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleMerge}
          autoFocus
          color="inherit"
          style={{
            backgroundColor: addingItemToOrder ? "gray" : "#5CBF38",
            color: "white",
          }}
          variant={"contained"}
          disabled={addingItemToOrder}
        >
          {addingItemToOrder ? "Merging" : "Merge"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialogRaw.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  lineItems: PropTypes.array.isRequired,
  orderUUID: PropTypes.string.isRequired,
};

export default function ActiveItemConfirmationDialog({ orderData }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleCancel = () => {
    history.push("/cart");
    dispatch(getIikoActiveOrders());
  };

  return (
    <ConfirmationDialogRaw
      keepMounted
      open={true}
      onCancel={handleCancel}
      lineItems={orderData?.line_items || []}
      orderUUID={orderData?.uuid}
    />
  );
}
