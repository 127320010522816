import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Header from "components/Emenu/Header/Header.js";
import CheckoutStyle from "./CheckoutStyle";
import { useSelector } from "react-redux";
import firebase from "../../constants/Firebase";
import { Helmet } from "react-helmet";
import CartTotals from "../../components/Emenu/Cart/CartTotals";
import CheckoutData from "../../components/Emenu/Checkout/CheckoutData";
import CartOrderType from "../../components/Emenu/Cart/CartOrderType";
import { isEmpty } from "../../constants/utils";
import SubmitOrderButton from "../../components/Emenu/Checkout/SubmitOrderButton";
import { useForm } from "react-hook-form";
import CartDeliveryButton from "../../components/Emenu/Cart/CartDeliveryButton";
import { useHistory } from "react-router-dom";
import CheckoutTerms from "../../components/Emenu/Checkout/CheckoutTerms";

const useStyles = makeStyles(CheckoutStyle);

export default function CheckoutPage(props) {
  const history = useHistory();
  const menuInfo = useSelector((state) => state.menuInfo);
  const cartInfo = useSelector((state) => state.cartInfo);
  const {
    ui_strings,
    theme_data,
    license,
    enable_address_google_maps,
    enable_display_order_type_home,
    al_futtaim_enabled,
  } = menuInfo.menuData;
  const {
    products,
    enableWishlist,
    paymentType,
    deliveryLocation,
    deliveryLocationConfirmed,
    deliveryFormattedAddress,
    orderType,
    minOrderAmountError,
    orderLocation,
    orderLocationType,
    customerName,
    customerEmail,
    customerPhone,
    carNumber,
  } = cartInfo;

  const {
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      name: customerName,
      email: customerEmail,
      phone: customerPhone,
      carNumber: carNumber,
      checkout_terms: false,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.cart_screen_bg_color;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor = theme_data?.cart_screen_bg_color;
    }

    if (license) {
      firebase.analytics().logEvent("checkout_page_view", {
        license: license,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!paymentType) {
      history.push("/cart");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType]);

  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <Header
        goback
        brand={ui_strings?.ui_str_cart ? ui_strings.ui_str_cart : "Cart"}
      />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${
                ui_strings?.ui_str_checkout
                  ? ui_strings.ui_str_checkout
                  : "Checkout"
              } | ${menuInfo.menuData?.name} | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div className={classes.main}>
        <div className={classes.container}>
          {!enable_display_order_type_home && <CartOrderType />}
          {!enableWishlist && minOrderAmountError && <CartTotals />}

          {((products.length > 0 &&
            !enableWishlist &&
            !minOrderAmountError &&
            !orderLocation &&
            !orderLocationType &&
            paymentType &&
            orderType) ||
            (products.length > 0 &&
              !enableWishlist &&
              !minOrderAmountError &&
              orderType !== "dine_in" &&
              orderLocation &&
              orderLocationType)) && (
            <div style={{ width: "100%" }}>
              {(!isEmpty(deliveryLocation) &&
                deliveryLocationConfirmed &&
                deliveryFormattedAddress &&
                orderType === "delivery") ||
              (!enable_address_google_maps && orderType === "delivery") ||
              orderType !== "delivery" ? (
                <form>
                  <CheckoutData control={control} />
                  <CartTotals />
                  <CheckoutTerms control={control} />
                  <SubmitOrderButton
                    handleSubmit={handleSubmit}
                    errors={errors}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                  />
                </form>
              ) : (
                <>{orderType === "delivery" && <CartDeliveryButton />}</>
              )}
            </div>
          )}

          {products.length > 0 &&
            !enableWishlist &&
            !minOrderAmountError &&
            orderLocation &&
            orderLocationType &&
            paymentType &&
            orderType === "dine_in" && (
              <div style={{ width: "100%" }}>
                <form>
                  <div style={{ fontWeight: "bold" }}>
                    {orderLocationType}# {orderLocation}
                  </div>
                  {al_futtaim_enabled && <CheckoutData control={control} />}
                  <CartTotals />
                  <CheckoutTerms control={control} />
                  <SubmitOrderButton
                    handleSubmit={handleSubmit}
                    errors={errors}
                    isValid={isValid}
                    isSubmitting={isSubmitting}
                  />
                </form>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
