import PaymentPage from "./PaymentPage";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APP_URL } from "../../constants/Api";

export default function NgeniusPage(props) {
  const params = new URLSearchParams(props.location.search);
  const reference = params.get("ref");
  const [status, setStatus] = useState("PENDING");
  const [orderId, setOrderId] = useState("");
  const [orderUUID, setOrderUUID] = useState("");

  // Get order status
  useEffect(() => {
    if (reference) {
      axios
        .post(
          `${APP_URL}/en/ngenius/status/`,
          JSON.stringify({
            reference: reference,
          }),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            setStatus(response.data.status);
            setOrderId(response.data.orderId);
            setOrderUUID(response.data.orderUUID);
          }
        })
        .catch((error) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            //console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the
            // browser and an instance of
            // http.ClientRequest in node.js
            //console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            //console.log("Error", error.message);
          }
          //console.log(error.config);
        });
    }
  }, [reference]);

  return (
    <PaymentPage status={status} reference={orderId} order_uuid={orderUUID} />
  );
}
