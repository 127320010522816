import { SEARCH_TEXT } from "../actions/types";

const initialState = {
  searchTerm: "",
  checkedProperties: [],
};

const SearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_TEXT:
      return {
        searchTerm: action.searchTerm,
        checkedProperties: action.checkedProperties,
      };
    default:
      return state;
  }
};

export default SearchReducer;
