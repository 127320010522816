import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import { fontName, is_open } from "../../../constants/utils";
import Card from "../../Card/Card";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import CardHeader from "../../Card/CardHeader";
import { Images } from "../../../constants";
import CardBody from "../../Card/CardBody";

const useStyles = makeStyles(CategoryStyle);

export default function RenderSubCategories(props) {
  const { currentCategory, subcatagories } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    timezone,
    theme_data,
    default_category_image,
    default_category_image_width,
    default_category_image_height,
  } = menuInfo.menuData;

  const categoryCardStyle = {
    fontSize: Number(theme_data?.home_screen_category_title_font_size),
    color: theme_data?.home_screen_category_title_text_color,
    ...fontName(theme_data?.home_screen_category_title_font_family),
  };

  const defaultCategoryImage = {
    image: default_category_image?.resized,
    width: default_category_image_width,
    height: default_category_image_height,
  };

  return (
    <div>
      {currentCategory.description && (
        <div
          className={classes.categoryDescription}
          dangerouslySetInnerHTML={{ __html: currentCategory.description }}
        />
      )}
      <div className={classes.categories}>
        {subcatagories
          .filter((category) => is_open(category.category_hours, [], timezone))
          .map((category) => (
            <Card
              key={category.id}
              className={classes.card}
              style={{
                backgroundColor: !category.hidden_title
                  ? theme_data?.home_screen_category_bg_color
                  : "transparent",
                width: category.fullwidth ? "95%" : "45%",
              }}
              plain={!category.card_shadow}
            >
              <EmenuNavlink
                disabled={category.disable_link}
                external={category.external_link}
                to={{
                  pathname: `/category/${category.id}`,
                  categoryData: category,
                }}
              >
                <CardHeader
                  image
                  plain
                  noShadow={!category.card_shadow}
                  children={
                    !category.hidden_image ? (
                      <img
                        src={
                          category.image?.product
                            ? category.image.product
                            : defaultCategoryImage.image
                            ? defaultCategoryImage.image
                            : Images.PlaceHolderProduct.image
                        }
                        className={classes.categoryImage}
                        width={
                          category.image?.product
                            ? category.image_width
                            : defaultCategoryImage.width
                            ? defaultCategoryImage.width
                            : Images.PlaceHolderProduct.width
                        }
                        height={
                          category.image?.product
                            ? category.image_height
                            : defaultCategoryImage.height
                            ? defaultCategoryImage.height
                            : Images.PlaceHolderProduct.height
                        }
                        alt={category.name}
                      />
                    ) : null
                  }
                />
                {!category.hidden_title ? (
                  <CardBody
                    className={classes.categoryTitle}
                    children={
                      <div
                        style={{
                          padding: category.hidden_image ? "14px 0" : 0,
                          ...categoryCardStyle,
                        }}
                      >
                        {category.name}
                      </div>
                    }
                  />
                ) : null}
              </EmenuNavlink>
            </Card>
          ))}
      </div>
    </div>
  );
}
