import TableCell from "@material-ui/core/TableCell";

const CustomTableCell = (props) => {
  const { children, ...rest } = props;
  return (
    <TableCell style={{ fontSize: "12px" }} {...rest}>
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
