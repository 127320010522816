import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Button, DialogContentText } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";

export default function AlertDialog(props) {
  const {
    alertOpen,
    setAlertOpen,
    alertTitle,
    alertContent,
    buttonText,
  } = props;

  const handleClose = () => {
    setAlertOpen(false);
  };

  return (
    <Dialog
      open={alertOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
        {alertTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={handleClose} color="primary" autoFocus>
          {buttonText ? buttonText : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
