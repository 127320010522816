import firebase from "firebase/app";
import "firebase/analytics";

import { FIREBASE_CONFIG } from "./Api";

firebase.initializeApp(FIREBASE_CONFIG);
// Load analytics
firebase.analytics();

export default firebase;
