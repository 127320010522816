import React from "react";
import Button from "../../CustomButtons/Button";
import EmenuNavlink from "../Navlink/EmenuNavlink";
import { fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CartStyle from "../../../views/CartPage/CartStyle";
const useStyles = makeStyles(CartStyle);

export default function CartButton(props) {
  const { noText } = props;
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { ui_strings, theme_data } = menuInfo.menuData;

  const buttonStyle = {
    backgroundColor: theme_data?.cart_screen_continue_shopping_btn_color,
    color: theme_data?.cart_screen_continue_shopping_btn_text_color,
    fontSize: Number(theme_data?.cart_screen_continue_shopping_btn_font_size),
    ...fontName(theme_data?.cart_screen_continue_shopping_btn_font_family),
  };

  return (
    <div className={classes.menuButtonContainer}>
      <EmenuNavlink to={"/home"}>
        <Button fullWidth className={classes.menuButton} style={buttonStyle}>
          {ui_strings?.ui_str_continue_shopping
            ? ui_strings.ui_str_continue_shopping
            : "Explore Menu"}
        </Button>
      </EmenuNavlink>
      <div style={{ fontSize: 12, display: noText ? "none" : "block" }}>
        {ui_strings?.ui_str_add_more_items_to_cart
          ? ui_strings.ui_str_add_more_items_to_cart
          : "Add more items to your cart"}
      </div>
    </div>
  );
}
