import { combineReducers } from "redux";
import MenuReducer from "./MenuReducer";
import CartReducer from "./CartReducer";
import SearchReducer from "./SearchReducer";

export default combineReducers({
  menuInfo: MenuReducer,
  cartInfo: CartReducer,
  searchInfo: SearchReducer,
});
