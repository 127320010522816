import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const CheckoutTerms = ({ control }) => {
  const menuInfo = useSelector((state) => state.menuInfo);
  const { checkout_terms, theme_data } = menuInfo.menuData;
  const [checked, setChecked] = useState(false);

  return (
    <>
      {checkout_terms && (
        <Controller
          name="checkout_terms"
          render={({ onChange, name }) => (
            <FormControlLabel
              control={
                <Checkbox
                  id={name}
                  onChange={() => {
                    setChecked(!checked);
                    onChange(!checked);
                  }}
                  checked={checked}
                  inputProps={{ "aria-label": "agree terms" }}
                  style={{
                    color: `${theme_data?.cart_screen_form_input_border_color}`,
                  }}
                />
              }
              label={
                <div>
                  I've read and accept the{" "}
                  <NavLink to={`/page/${checkout_terms}`} ex>
                    terms and conditions
                  </NavLink>
                </div>
              }
              style={{
                color: `${theme_data?.cart_screen_form_input_border_color}`,
                marginBottom: "30px",
              }}
            />
          )}
          control={control}
          rules={{
            required: {
              value: true,
            },
          }}
        />
      )}
    </>
  );
};

export default CheckoutTerms;
