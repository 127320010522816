import { container, main, mainRaised } from "assets/jss/material-kit-pro-react";

const InformationStyle = {
  main: {
    ...main,
    /*overflow: "hidden"*/
    background: "transparent",
  },
  mainContainer: {
    position: "relative",
    width: "inherit",
    maxWidth: "inherit",
  },
  mainRaised,
  container: {
    ...container,
    zIndex: 1,
    minHeight: "100vh",
    paddingTop: 70,
  },
  page: {
    padding: 20,
    "& ul, & ol": {
      paddingLeft: 20,
    },
    lineHeight: "14px",
  },
};

export default InformationStyle;
