const config = {
  onUpdate: async (registration) => {
    if (registration && registration.waiting) {
      registration.waiting.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
    }
  },
};

export default config;
