import React from "react";
import { fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";

export default function MenuClosed(props) {
  const { text, margins } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const { theme_data } = menuInfo.menuData;

  return (
    <div
      style={{
        backgroundColor: theme_data?.header_menu_closed_bg_color,
        fontSize: Number(theme_data?.header_menu_closed_font_size),
        color: theme_data?.header_menu_closed_text_color,
        ...fontName(theme_data?.header_menu_closed_font_family),
        padding: 5,
        marginTop: margins ? 10 : 0,
        marginBottom: margins ? 10 : 0,
        marginLeft: margins ? 0 : -15,
        marginRight: margins ? 0 : -15,
        textAlign: "center",
      }}
    >
      {text}
    </div>
  );
}
