import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import OrderStyle from "../../../views/OrderPage/OrderStyle";

const useStyles = makeStyles(OrderStyle);

const OrderTotalsItems = ({ line_items }) => {
  const classes = useStyles();
  const menuInfo = useSelector((state) => state.menuInfo);
  const { price_decimal_places } = menuInfo.menuData;

  if (!line_items) {
    return null;
  }

  return line_items?.map((item, index) => (
    <tr
      key={index}
      style={{
        verticalAlign: "top",
        textDecoration: item.status === "Deleted" ? "line-through" : "none",
      }}
    >
      <td style={{ textAlign: "center" }}>{item?.quantity}</td>
      <td>
        <div>{item?.product_name}</div>
        <div style={{ fontSize: 12, whiteSpace: "pre-line" }}>
          {item?.product_variation}
        </div>
        {item?.combo_groups?.length > 0 && (
          <div style={{ fontSize: 12, marginBottom: 2 }}>
            {item?.combo_groups?.map((cg, index) => {
              return (
                <ul key={index} className={classes.itemComboList}>
                  <li className={classes.itemComboListItem}>
                    {cg?.name}
                    <ul className={classes.itemComboList}>
                      {cg?.products
                        ?.filter((cgp) => cgp.selected)
                        .map((cgp, index) => {
                          return (
                            <li
                              key={index}
                              className={classes.itemComboListItem}
                            >
                              {cgp?.name}
                              <ul className={classes.itemComboList}>
                                {cgp?.product_variations?.map(
                                  (variation, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={classes.itemComboListItem}
                                      >
                                        {variation.name}:
                                        <ul className={classes.itemComboList}>
                                          {variation.values.map((v, i) =>
                                            v.selected ? (
                                              <li
                                                key={i}
                                                className={
                                                  classes.itemComboListItem
                                                }
                                              >
                                                {v.name}
                                              </li>
                                            ) : null
                                          )}
                                        </ul>
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                </ul>
              );
            })}
          </div>
        )}
        <div style={{ fontSize: 12, fontStyle: "italic" }}>
          {item?.special_instructions}
        </div>
      </td>
      <td style={{ textAlign: "right" }}>
        {parseFloat(item?.product_price * item?.quantity).toFixed(
          price_decimal_places
        )}
      </td>
    </tr>
  ));
};

export default OrderTotalsItems;
