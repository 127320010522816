import React, { forwardRef, useCallback, useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { currency_symbol, flat, fontName } from "../../../constants/utils";
import Select from "@material-ui/core/Select";
import { css } from "@emotion/css";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CategoryStyle from "../../../views/CategoryPage/CategoryStyle";
import * as math from "mathjs";
import { addCart } from "../../../store/actions/cart";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "../../CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(CategoryStyle);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function VariationModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    currency,
    currency_symbol: cSymbol,
    display_currency,
    price_decimal_places,
    ui_strings,
    addtocart_redirect,
    variations,
    enable_display_variation_prices,
    theme_data,
    taxes,
    display_prices_with_tax,
  } = menuInfo.menuData;

  const { product, handleClose, handleOpen } = props;
  const { product_variations } = product;
  const [productVariations, setProductVariations] = useState([]);

  const [special_instructions] = useState("");

  const [requiredVariationsSet, setRequiredVariationsSet] = useState(false);

  const [price, setPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);

  const getTaxes = useCallback(
    (price) => {
      let taxAmount = 0;
      if (taxes.length > 0 && display_prices_with_tax) {
        for (let tax of taxes) {
          if (tax.type === "percentage") {
            taxAmount += (parseFloat(price) * parseFloat(tax.rate)) / 100;
          }
        }
      }
      return taxAmount;
    },
    [display_prices_with_tax, taxes]
  );

  const getVariationPrice = (variationPrice) => {
    let taxes;
    const price = parseFloat(variationPrice);
    const discountedPrice =
      parseFloat(variationPrice) -
      (parseFloat(variationPrice) * product.discount) / 100;
    if (product.discount > 0) {
      taxes = getTaxes(discountedPrice);
      return (parseFloat(discountedPrice) + taxes).toFixed(
        price_decimal_places
      );
    } else {
      taxes = getTaxes(price);
      return (parseFloat(price) + taxes).toFixed(price_decimal_places);
    }
  };

  const handleAddToCart = () => {
    if (requiredVariationsSet) {
      dispatch(
        addCart(
          uuidv4(),
          product.id,
          product.category,
          product.name,
          product.sku,
          product.images[0] ? product.images[0].image : null,
          discountedPrice > 0 ? discountedPrice : price,
          1,
          special_instructions,
          productVariations,
          [],
          product.pos_available_qty
        )
      );
      handleClose();
      if (addtocart_redirect === "cart") {
        history.push("/cart");
      } else if (addtocart_redirect === "back") {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    // Also update the product price based on variation selection
    //Object.keys(product).length && console.log(product);
    setProductVariations(
      product_variations
        ? variations
            .filter((v) =>
              [
                ...new Set(product_variations.map((pv) => pv.variation)),
              ].includes(v.id)
            )
            .map((v) => ({
              ...v,
              values: v.values.filter((vv) =>
                product_variations.find((pv) => pv.variation_value === vv.id)
              ),
            }))
            .map((v) => ({
              ...v,
              values: v.values.map((vv) => ({
                ...product_variations.find(
                  (pv) => pv.variation_value === vv.id && pv
                ),
                name: vv.value,
                selected: false,
              })),
            }))
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_variations]);

  useEffect(() => {
    // Also update the product price and discounted based on variation selection
    let price = product.price;
    let discountedPrice =
      product.discount > 0
        ? product.price - (product.price * product.discount) / 100
        : 0;

    let selectedVariations = flat(
      productVariations.map((v) => v.values)
    ).filter((v) => v.selected);

    selectedVariations.forEach(function (sv) {
      price = math.evaluate(`${price} ${sv.math} ${sv.price}`).toFixed(3);
      discountedPrice =
        discountedPrice > 0
          ? math
              .evaluate(
                `${discountedPrice} ${sv.math} ${sv.price} - ${sv.price} * ${product.discount}/100`
              )
              .toFixed(3)
          : 0;
    });
    // eslint-disable-next-line no-unused-expressions
    discountedPrice > 0
      ? setDiscountedPrice(
          parseFloat(discountedPrice) + getTaxes(discountedPrice)
        )
      : null;
    setPrice(parseFloat(price) + getTaxes(price));
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // Check if all required variations are selected
    const requiredVariations = productVariations.filter(
      (v) => v.required === true
    );
    if (requiredVariations.length > 0) {
      const requiredVariationsSelected = requiredVariations.every(
        (rv) => rv.values.find((vv) => vv.selected) !== undefined
      );
      setRequiredVariationsSet(requiredVariationsSelected);
    } else {
      setRequiredVariationsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productVariations]);

  const handleVariationSelection = (event) => {
    const elementsIndex = productVariations.findIndex(
      (element) => element.name === event.target.name
    );
    let newProductVariations = [...productVariations];
    newProductVariations[elementsIndex] = {
      ...newProductVariations[elementsIndex],
      values: newProductVariations[elementsIndex].values.map((vv) =>
        vv.variation_value === event.target.value
          ? { ...vv, selected: !vv.selected }
          : { ...vv, selected: false }
      ),
    };
    // Set uniqueVariation state
    setProductVariations(newProductVariations);
    // console.log(newProductVariations);
  };

  const handleMultipleVariationSelection = (variation_value, index) => (
    event
  ) => {
    let newProductVariations = [...productVariations];
    newProductVariations[index] = {
      ...newProductVariations[index],
      values: newProductVariations[index].values.map((vv) =>
        vv.variation_value === variation_value
          ? { ...vv, selected: !vv.selected }
          : vv
      ),
    };
    // Set uniqueVariation state
    setProductVariations(newProductVariations);
    // console.log(newProductVariations);
  };

  return (
    Object.keys(product).length > 0 && (
      <Dialog
        classes={{
          root: classes.modalRoot,
          paper: classes.modal,
        }}
        open={handleOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-labelledby="variation-modal-title"
        aria-describedby="variation-modal-description"
      >
        <DialogTitle
          id="variation-modal-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => handleClose()}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {ui_strings?.ui_str_select_variations
              ? ui_strings.ui_str_select_variations
              : "Select Variations"}
          </h4>
        </DialogTitle>
        <DialogContent
          id="variation-modal-description"
          className={classes.modalBody}
        >
          <div>
            <div style={{ marginBottom: 20 }}>
              {display_currency
                ? cSymbol
                  ? cSymbol
                  : currency_symbol(currency)
                : null}
              {discountedPrice > 0
                ? parseFloat(discountedPrice).toFixed(price_decimal_places)
                : parseFloat(price).toFixed(price_decimal_places)}
            </div>
            {productVariations.map((pv, index) => (
              <div style={{ paddingBottom: 20 }} key={index}>
                <FormControl fullWidth>
                  {pv.multiple ? (
                    <>
                      <FormLabel
                        component="legend"
                        style={{
                          fontSize: Number(
                            theme_data?.product_screen_variation_dropdown_title_font_size
                          ),
                          color:
                            theme_data?.product_screen_variation_dropdown_title_text_color,
                          ...fontName(
                            theme_data?.product_screen_variation_dropdown_title_font_family
                          ),
                        }}
                      >
                        <div>
                          {pv.name}
                          {pv.required ? (
                            <>
                              <span style={{ color: "red" }}> * </span>
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  lineHeight: "12px",
                                  marginTop: 8,
                                  marginBottom: 8,
                                }}
                              >
                                Required
                              </div>
                            </>
                          ) : null}
                          {pv.max_choices_allowed > 0 ? (
                            <div
                              style={{
                                fontSize: 12,
                                color: "grey",
                                marginTop: 8,
                                marginBottom: 8,
                              }}
                            >
                              Choose upto {pv.max_choices_allowed}
                              {pv.max_choices_allowed > 1
                                ? ` options`
                                : ` option`}
                            </div>
                          ) : null}
                        </div>
                      </FormLabel>
                      <FormGroup>
                        {pv.values.map((variation, pv_index) => (
                          <FormControlLabel
                            key={pv_index}
                            control={
                              <Checkbox
                                checked={
                                  pv.values.find(
                                    (v) =>
                                      v.variation_value ===
                                      variation.variation_value
                                  )
                                    ? pv.values.find(
                                        (v) =>
                                          v.variation_value ===
                                          variation.variation_value
                                      ).selected
                                    : false
                                }
                                disabled={
                                  pv.max_choices_allowed > 0 &&
                                  pv.values.filter((pvv) => pvv.selected)
                                    .length >= pv.max_choices_allowed &&
                                  pv.values
                                    .filter((pvv) => !pvv.selected)
                                    .find(
                                      (v) =>
                                        v.variation_value ===
                                        variation.variation_value
                                    )
                                }
                                onChange={handleMultipleVariationSelection(
                                  variation.variation_value,
                                  index
                                )}
                                value={variation.name}
                              />
                            }
                            label={
                              <Typography
                                style={{
                                  color:
                                    theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                                  fontSize: Number(
                                    theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
                                  ),
                                  ...fontName(
                                    theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
                                  ),
                                }}
                              >
                                {variation.name}
                                {` ${
                                  variation.price > 0 &&
                                  enable_display_variation_prices
                                    ? `(${variation.math}
                    ${
                      display_currency
                        ? cSymbol
                          ? cSymbol
                          : currency_symbol(currency)
                        : ``
                    }
                    ${getVariationPrice(variation.price)})`
                                    : ``
                                }`}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </>
                  ) : (
                    <>
                      <InputLabel
                        htmlFor={`${pv.name}`}
                        className={classes.selectShrink}
                        style={{
                          fontSize: Number(
                            theme_data?.product_screen_variation_dropdown_title_font_size
                          ),
                          color:
                            theme_data?.product_screen_variation_dropdown_title_text_color,
                          ...fontName(
                            theme_data?.product_screen_variation_dropdown_title_font_family
                          ),
                          zIndex: 1,
                          padding: "0 10px",
                          pointerEvents: "none",
                        }}
                      >
                        {pv.name}
                        {pv.required ? "*" : null}
                      </InputLabel>
                      <Select
                        style={{
                          backgroundColor:
                            theme_data?.product_screen_variation_dropdown_btn_color,
                          color:
                            theme_data?.product_screen_variation_dropdown_btn_selected_text_color,
                          fontSize: Number(
                            theme_data?.product_screen_variation_dropdown_btn_selected_font_size
                          ),
                          ...fontName(
                            theme_data?.product_screen_variation_dropdown_btn_selected_font_family
                          ),
                          padding: "0 10px",
                        }}
                        className={css`
                          &::after {
                            border-bottom: 2px solid
                              ${theme_data?.product_screen_variation_dropdown_btn_selected_text_color};
                          }
                        `}
                        value={
                          pv.values.find((v) => v.selected)
                            ? pv.values.find((v) => v.selected).variation_value
                            : ""
                        }
                        onChange={handleVariationSelection}
                        inputProps={{
                          name: `${pv.name}`,
                          id: `${pv.id}`,
                        }}
                      >
                        {!pv.required && (
                          <MenuItem
                            style={{
                              color:
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                              fontSize: Number(
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
                              ),
                              ...fontName(
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
                              ),
                            }}
                          >
                            {"---"}
                          </MenuItem>
                        )}
                        {pv.values.map((variation) => (
                          <MenuItem
                            key={variation.variation_value}
                            style={{
                              color:
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_text_color,
                              fontSize: Number(
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_font_size
                              ),
                              ...fontName(
                                theme_data?.product_screen_variation_dropdown_btn_dropdown_font_family
                              ),
                            }}
                            value={variation.variation_value}
                          >
                            {variation.name}
                            {` ${
                              variation.price > 0 &&
                              enable_display_variation_prices
                                ? `(${variation.math}
                    ${
                      display_currency
                        ? cSymbol
                          ? cSymbol
                          : currency_symbol(currency)
                        : ``
                    }
                    ${getVariationPrice(variation.price)})`
                                : ``
                            }`}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </FormControl>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions
          className={classNames(
            classes.modalFooter,
            `save-variations-${product.id}`
          )}
        >
          <Button
            style={{
              backgroundColor: theme_data?.product_screen_add_to_cart_btn_color,
              color: theme_data?.product_screen_add_to_cart_text_color,
            }}
            onClick={() => handleAddToCart()}
            disabled={!requiredVariationsSet || product.out_of_stock}
          >
            {product.out_of_stock
              ? ui_strings?.ui_str_out_of_stock
                ? ui_strings.ui_str_out_of_stock
                : "Out of Stock"
              : ui_strings?.ui_str_add_to_cart
              ? ui_strings.ui_str_add_to_cart
              : "Add To Cart"}
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}
