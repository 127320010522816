import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";
import { persistor, store } from "./store";
import "assets/css/material-kit-pro-react.min.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "assets/css/emenu.min.css";
import WebFont from "webfontloader";
import Switches from "./components/Emenu/Switches";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import serviceWorkerConfig from "./serviceWorkerConfig";

WebFont.load({
  custom: {
    families: [
      "Gill Sans",
      "ToxTypewriter",
      "Beatrice Display Medium",
      "Calibre Thin",
      "MSLondon",
    ],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <Switches />
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(serviceWorkerConfig);
